import {
	CognitoUserPool,
	CognitoUser,
	CognitoUserSession,
	CognitoAccessToken,
	CognitoRefreshToken,
	CognitoIdToken,
} from 'amazon-cognito-identity-js';
import * as AWSSDK from 'aws-sdk';
import {
	CodeMismatchException,
	CognitoIdentityProviderClient,
	ExpiredCodeException,
	LimitExceededException,
	InitiateAuthCommand,
	InitiateAuthCommandInput,
	NotAuthorizedException,
	PasswordResetRequiredException,
	RespondToAuthChallengeCommand,
	RespondToAuthChallengeCommandInput,
	UserNotConfirmedException,
	UserNotFoundException,
	RespondToAuthChallengeCommandOutput,
} from '@aws-sdk/client-cognito-identity-provider';
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { Environment } from '../../config/environment';
import { AppUtil } from '../../utils/app.util';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { Config } from '../../config/config';
import { DataCarrierService } from './data-carrier.service';
import { UTMParameters } from '../../beans/auth/utm-parameters.bean';
import { UtmParams } from '../../modules/scheduling/types.scheduling';
import { JwtHelper } from './jwt-helper.service';
import { APP_USER_PERMISSIONS, PERMISSON_ALLOWED_KEY } from 'src/app/constants/app-user-permissions.constants';
import { NgxPermissionsService } from 'ngx-permissions';
import { AwsSdkCredentialsProvider } from 'src/app/utils/AwsSdkCredentialsProvider';
import { MfaService } from './mfa.service';
import { SpinnerComponent } from 'src/app/modules/shared/spinner/spinner.component';
import { AUTH_COMPONENTS } from 'src/app/constants/scheduling.constants';
import { ModalService } from './modal.service';

/** AWS Object */
declare let AWS: any;
/** Cognito pool data object */
let PoolData = {
	ClientId: '',
	UserPoolId: '',
	IdentityPoolId: '',
};
/** hold user pool object */
let userPool;

interface AuthResult {
	authenticated: boolean;
	accessToken: string;
	refreshToken: string;
	idToken: string;
}

const LOGIN_SESSION_TIMEOUT = 3 * 60 * 1000; // 3 minutes - Found in Cognito - this is default value

/**
 * Cognito related authentication services
 */
@Injectable({
	providedIn: 'root',
})
export class AuthService {
	/** Observable returning the status cognito operations */
	result: Subject<any>;
	signUpResult: Subject<any>;
	username: string = '';
	password: string = '';
	response: any;
	cognitoUser: any;
	loginSessionTimeout: NodeJS.Timeout | string | number | undefined;

	private cognitoClient: CognitoIdentityProviderClient;
	/**
	 * Auth Service constructor
	 * @param environment - Environment Config
	 * @param config - URL Config
	 * @param dataCarrier - Data Carrier Service
	 * @param router - Angular Router
	 */
	constructor(
		private readonly environment: Environment,
		private readonly config: Config,
		private readonly dataCarrier: DataCarrierService,
		private readonly jwtHelper: JwtHelper,
		private readonly permissionService: NgxPermissionsService,
		private mfaService: MfaService,
		private spinner: SpinnerComponent,
		private modalService: ModalService,
	) {
		const env: any = this.environment.getEnvironment();
		/** constricting cognito pool data using cognito credentials */
		PoolData = {
			ClientId: env.CLIENT_ID,
			UserPoolId: env.USER_POOL_ID,
			IdentityPoolId: env.IDENTITY_POOL_ID,
		};
		userPool = new CognitoUserPool(PoolData);
		this.result = new Subject<any>();
		this.signUpResult = new Subject<any>();
	}

	async configureCognitoClient() {
		try {
			const credentialsProvider = new AwsSdkCredentialsProvider();
			const env: any = this.environment.getEnvironment();
			const creds = (await credentialsProvider.getCredentialsAndIdentityId()).credentials;
			this.cognitoClient = new CognitoIdentityProviderClient({
				region: env.AWS_REGION,
				credentials: {
					accessKeyId: creds.accessKeyId,
					secretAccessKey: creds.secretAccessKey,
				},
			});
			return this.cognitoClient;
		} catch (error) {
			console.error('Error configuring Cognito client:', error);
			throw new Error('Failed to configure Cognito client');
		}
	}

	/**
	 * Getter method for IDToken
	 */
	getToken(): string {
		let jwtToken = null;
		if (sessionStorage[this.config.TOKEN_NAME]) {
			const authToken = JSON.parse(sessionStorage[this.config.TOKEN_NAME]);
			jwtToken = authToken.idToken.jwtToken;
		}
		return jwtToken;
	}

	loadAppLevelPermissions() {
		const applicationPermissionsForLoggedInUser = this.getAppPermissionsForLoggedInUser();
		if (applicationPermissionsForLoggedInUser) {
			applicationPermissionsForLoggedInUser.forEach(permission => {
				this.permissionService.addPermission(permission);
			});
		}
		// console.log('permissions available are ', this.permissionService.getPermissions());
	}

	getAppPermissionsForLoggedInUser() {
		const decodedToken = this.jwtHelper.getDecodedToken();
		if (decodedToken && decodedToken[PERMISSON_ALLOWED_KEY]) {
			const permissionsStringArray = decodedToken ? decodedToken[PERMISSON_ALLOWED_KEY].split(',') : null;
			if (permissionsStringArray) {
				// parse string into enumerables thus only allowing permissions which are mutually decided both on FE and BE
				const permissions = permissionsStringArray
					.map((permission: string) => {
						if (permission in APP_USER_PERMISSIONS) {
							return APP_USER_PERMISSIONS[permission];
						}
						return null;
					})
					.filter(p => p);
				return permissions;
			}
		}
		return null;
	}

	async hasPermission(permission: string): Promise<boolean> {
		const permissions = this.permissionService.getPermissions();
		return !!permissions[permission];
	}

	/**
	 * Getter method for Token expiry date
	 * @param token IDToken
	 */
	getTokenExpirationDate(token: string): Date {
		const decoded = jwtDecode<JwtPayload>(token);
		if (decoded.exp === undefined) {
			return null;
		}
		const date = new Date(decoded.exp);
		return date;
	}

	/**
	 * checking whether the given token expired or not
	 * by checking expiry timestamp of token
	 * @param token - IDToken
	 * return true or false
	 */
	isTokenExpired(token?: string): boolean {
		if (!token) {
			token = this.getToken();
		}
		if (!token) {
			return true;
		}
		const date = this.getTokenExpirationDate(token);
		if (date === undefined) {
			return false;
		}
		// Rounding off current timestamp to 10 digits
		const currentTimestamp = Math.floor(new Date().valueOf() / 1000);
		return !(date.valueOf() > currentTimestamp);
	}

	/**
	 * Cognito Sign up
	 * @param object - contain user related information like email and password
	 * return the response using Observable
	 */
	signUpUser(object) {
		this.signUpResult = new Subject<any>();
		userPool.signUp(AppUtil.getFormattedEmail(object.email), object.password, null, null, (err, res) => {
			if (err) {
				console.error(`[${AuthService.name}][${this.signUpUser.name}]`, 'Error during user signup', object.email, err);
				const error = { success: false, object: err };
				this.signUpResult.error(error);
			} else {
				this.signUpResult.next({ success: true, object: '' });
			}
		});
	}

	private clearStorageAndCredentials() {
		/**
		 * clear local storage, session storage and  AWS Credentials
		 * before new session begins
		 */
		// Getting utm params which is set before login, and setting back to local storgae
		const utm = localStorage.getItem('utm');
		const forgotPassToaster = sessionStorage.getItem('forgotPassToaster');
		const createAccountToaster = sessionStorage.getItem('createAccountToaster');
		const globalMfaStatus = this.mfaService.ss_getGlobalMfaStatus();
		const mfaActivatedAt = this.mfaService.ss_getMfaActivatedAt();

		localStorage.clear();
		sessionStorage.clear();

		// Setting back the params in local storage
		localStorage.setItem('utm', utm);
		sessionStorage.setItem('forgotPassToaster', forgotPassToaster);
		sessionStorage.setItem('createAccountToaster', createAccountToaster);
		this.mfaService.ss_setGlobalMfaStatus(globalMfaStatus);
		this.mfaService.ss_setMfaActivatedAt(mfaActivatedAt);

		if (AWS.config.credentials) {
			AWS.config.credentials.clearCachedId();
		}
	}

	private handleLoginError(error: any) {
		if (error instanceof NotAuthorizedException) {
			this.result.error({
				message: 'Invalid username or password',
				code: '400',
				name: 'Invalid username or password',
			});
		} else if (error instanceof UserNotFoundException) {
			this.result.error({
				message: 'User not found',
				code: '404',
				name: 'UserNotFoundException',
			});
		} else if (error instanceof UserNotConfirmedException) {
			this.result.error({
				message: 'User is not confirmed',
				code: '403',
				name: 'UserNotConfirmedException',
			});
		} else if (error instanceof PasswordResetRequiredException) {
			this.result.error({
				message: 'Password reset is required',
				code: '401',
				name: 'PasswordResetRequiredException',
			});
		} else if (error instanceof LimitExceededException) {
			this.result.error({
				message: 'Maximum attempt exceeded',
				code: '400',
				name: 'LimitExceededException',
			});
		}
	}

	/**
	 * Cognito Login
	 * @param username - user email Id
	 * @param password - user password
	 */
	async login(username: string, password: string, authMetadata: string = AUTH_COMPONENTS.LOGIN_COMPONENT) {
		if (this.loginSessionTimeout) {
			console.info('LOGIN ALREADY IN PROGRESS');
			return;
		}

		this.result.subscribe({ error: () => { this.result = new Subject<any>(); } });

		const subscriptions: Subscription[] = [];
		subscriptions.push(
			this.mfaService.displayMfaInputScreen.subscribe(showMfaInputScreen => {
				if (!showMfaInputScreen) {
					this.clearLoginSessionTimeout();
				}
			})
		);
		try {
			this.mfaService.setUsername(username);
			this.mfaService.setPassword(password);
			subscriptions.push(
				this.mfaService.username.subscribe(username => {
					this.username = username;
				}),
				this.mfaService.password.subscribe(password => {
					this.password = password;
				}),
			);

			// Clear storage and AWS credentials
			this.clearStorageAndCredentials();
			let mfaCommandCode = 'RESEND'; // Initial 2FA OTP send after login
			await this.configureCognitoClient();
			let clientMetadata = {
				loginSource: authMetadata,
			};

			while (mfaCommandCode === 'RESEND' || mfaCommandCode.toString().startsWith('CONTINUE')) {
				if (mfaCommandCode === 'RESEND') {
					const input: InitiateAuthCommandInput = {
						AuthFlow: 'USER_PASSWORD_AUTH',
						AuthParameters: {
							PASSWORD: this.password,
							USERNAME: this.username,
						},
						ClientId: PoolData.ClientId,
						ClientMetadata: clientMetadata,
					};
					console.info('Auth command initiated');
					const command = new InitiateAuthCommand(input);

					this.response = await this.cognitoClient.send(command);
					this.cognitoUser = new CognitoUser({
						Username: this.username,
						Pool: userPool,
					});
					this.loginSessionTimeout = setTimeout(() => {
						this.handleLoginSessionExpiredForMfa();
					}, LOGIN_SESSION_TIMEOUT);
					console.info('MFA INPUT -> Login Session Started:', `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`);
					mfaCommandCode = 'CONTINUE';
				}

				if (this.response.ChallengeName === 'EMAIL_OTP') {
					console.info('EMAIL otp response received');
					mfaCommandCode = await this.promptForMFACode(this.response);

					if (mfaCommandCode.toString().startsWith('CONTINUE;')) {
						this.clearLoginSessionTimeout();
						const mfaOtpCode = mfaCommandCode.toString().split(';')[1];
						// mfaCommandCode = 'CONTINUE';
						let mfaVerifyResponse;
						try {
							mfaVerifyResponse = await this.completeMFAChallenge(
								this.response.ChallengeParameters?.USER_ID_FOR_SRP,
								mfaOtpCode,
								this.response.Session
							);
							console.info('MFA verification success', mfaVerifyResponse);
							if (mfaVerifyResponse) {
								const mfaActivatedAt = this.mfaService.ss_getMfaActivatedAt();
								if (!mfaActivatedAt || mfaActivatedAt === 'undefined') {
									let responseOfSuccessScreen = await this.showUpSuccessScreen();
									if (responseOfSuccessScreen == 'success') {
										this.cognitoSessionUpdate(mfaVerifyResponse, this.cognitoUser);
									}
								} else {
									this.cognitoSessionUpdate(mfaVerifyResponse, this.cognitoUser);
								}
								mfaCommandCode = ''; // Reset MFA code after successful verification
							} else {
								mfaCommandCode = 'CONTINUE'; // Set mfaCode to CONTINUE to allow retry
							}
						} catch (error) {
							mfaCommandCode = 'CONTINUE';
						}
					}
				} else if (this.response.AuthenticationResult) {
					// MFA is not required, user is authenticated
					console.info('User authenticated without MFA');
					this.cognitoSessionUpdate(this.response, this.cognitoUser);
					mfaCommandCode = '';
					this.clearLoginSessionTimeout();
				} else {
					throw new Error('Unexpected response from Cognito');
				}
				const authData = JSON.parse(sessionStorage.getItem('auth'));
				if (mfaCommandCode !== 'RESEND' && authData?.idToken) {
					console.info('setting up userLevelMFA value');
					this.mfaService.ss_setUserMfaStatus(JSON.parse(authData.idToken.payload['custom:mfa-enabled']));
					const mfaDisabledReason = authData.idToken.payload['custom:mfa-disable-reason'];
					if (mfaDisabledReason) {
						this.mfaService.ss_setMfaDisabledReason(mfaDisabledReason);
					}
					this.mfaService.fetchGlobalMfaStatus();
					this.mfaService.displayMfaErrorMessage(false, '');
				}
				console.info('MFA code at the end of loop : ' + mfaCommandCode);
			}
		} catch (error) {
			console.error('Login error:', error);
			this.clearLoginSessionTimeout();
			this.handleLoginError(error);
		}
		subscriptions.forEach(s => s.unsubscribe());
		console.info('Login Session Timeout:', this.loginSessionTimeout);
	}

	private cognitoSessionUpdate(response, cognitoUser) {
		// this.result = new Subject<any>();
		const cognitoUserSession = new CognitoUserSession({
			AccessToken: new CognitoAccessToken({ AccessToken: response.AuthenticationResult.AccessToken }),
			RefreshToken: new CognitoRefreshToken({ RefreshToken: response.AuthenticationResult.RefreshToken }),
			IdToken: new CognitoIdToken({ IdToken: response.AuthenticationResult.IdToken }),
		});
		sessionStorage.setItem(this.config.TOKEN_NAME, JSON.stringify(cognitoUserSession));
		sessionStorage.setItem('userId', cognitoUserSession.getIdToken().decodePayload().sub);
		cognitoUser.setSignInUserSession(cognitoUserSession);
		// FIX FITEHEME-986
		this.getAwsCredentials();
		this.result.next(cognitoUserSession);
	}

	private async promptForMFACode(cognitoResponse: RespondToAuthChallengeCommandOutput): Promise<string> {
		// console.info('cognitoResponse:', cognitoResponse);
		// Display the MFA input component
		this.mfaService.setCodeDeliveryDestination(cognitoResponse.ChallengeParameters?.['CODE_DELIVERY_DESTINATION']);
		this.mfaService.toggleDisplayMfaInputScreen(true);
		this.spinner.stopLoader(); // Stop the loading spinner
		return new Promise<string>(resolve => {
			// Subscribe to the mfaCode observable to get the entered MFA code
			const subscriptionsLocal = new Subscription();
			subscriptionsLocal.add(
				this.mfaService.mfaCode.subscribe(code => {
					if (code) {
						resolve(code); // Resolve the promise with the entered MFA code
						subscriptionsLocal.unsubscribe(); // Unsubscribe to avoid memory leaks
						this.mfaService.setMfaCode('');
						this.spinner.startLoader();
					}
				})
			);
			subscriptionsLocal.add(
				this.mfaService.displayMfaInputScreen.subscribe(showMfaInputScreen => {
					if (!showMfaInputScreen) {
						subscriptionsLocal.unsubscribe();
					}
				})
			);
		});
	}

	private async showUpSuccessScreen(): Promise<string> {
		this.spinner.stopLoader();
		const mfaActivatedAt = this.mfaService.ss_getMfaActivatedAt();
		if (!mfaActivatedAt || mfaActivatedAt === 'undefined') {
			this.mfaService.setMfaActivatedAt('mfaSet');
			return new Promise<string>(resolve => {
				const subscription = this.mfaService.displayMfaSuccessScreen.subscribe(code => {
					if (code === 'dismissed') {
						this.mfaService.ss_setMfaActivatedAt('mfaSet');
						subscription.unsubscribe();
						this.spinner.startLoader();
						this.mfaService.toggleDisplayMfaSuccessScreen('false');
						resolve('success');
					}
				});
			});
		}
	}

	async completeMFAChallenge(username: string, code: string, session: string) {
		const params = {
			ChallengeName: 'EMAIL_OTP',
			ClientId: PoolData.ClientId,
			ChallengeResponses: {
				USERNAME: username,
				EMAIL_OTP_CODE: code,
			},
			Session: session,
		} as RespondToAuthChallengeCommandInput;

		try {
			const command = new RespondToAuthChallengeCommand(params);
			const response = await this.cognitoClient.send(command);
			return response; // Return success if no error occurs
		} catch (error) {
			this.spinner.stopLoader();
			if (error instanceof NotAuthorizedException) {
				this.handleLoginSessionExpiredForMfa();
				return false; // Return failure with error
			} else if (error instanceof CodeMismatchException) {
				this.mfaService.displayMfaErrorMessage(
					true,
					'The code you entered is incorrect. Please re-enter the code to try again.'
				);
				return false; // Return failure with error
			} else if (error instanceof ExpiredCodeException) {
				this.mfaService.displayMfaErrorMessage(true, 'MFA code has expired');
				return false; // Return failure with error
			} else if (error instanceof LimitExceededException) {
				this.mfaService.displayMfaErrorMessage(true, 'Limit Exceeded');
				return false;
			} else {
				console.error('An unexpected error occurred during MFA verification:', error);
				this.mfaService.displayMfaErrorMessage(true, 'An unexpected error occurred during MFA verification');
				return false; // Return failure with error
			}
		}
	}

	clearLoginSessionTimeout(): void {
		if (this.loginSessionTimeout) {
			console.info('MFA INPUT -> Clearing Login Session Timeout!', this.loginSessionTimeout);
			clearInterval(this.loginSessionTimeout);
			this.loginSessionTimeout = undefined;
		}
	}

	handleLoginSessionExpiredForMfa(): void {
		console.info('MFA INPUT -> Login Session Expired:', `${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`);
		this.modalService.openSessionExpireModal(
			'LOGIN SESSION EXPIRED',
			`Your login session has expired, please try again and submit OTP within 3 minutes!`,
			() => {
				this.mfaService.toggleDisplayMfaInputScreen(false);
			}
		);
	}

	/**
	 * Retrieve AWS credentials of current user
	 */
	getAwsCredentials() {
		const cognitoGetUser = userPool.getCurrentUser();
		if (cognitoGetUser != null) {
			const _self = this;
			cognitoGetUser.getSession(function (err, result) {
				AWSSDK.config.region = 'us-east-1';
				if (result) {
					const Logins = {};
					Logins[_self.environment.getEnvironment().LOGINS_URL] = result.getIdToken().getJwtToken();
					AWSSDK.config.update({
						credentials: new AWSSDK.CognitoIdentityCredentials({
							IdentityPoolId: PoolData.IdentityPoolId,
							Logins: Logins,
						}),
					});
				}
			});
		}
	}

	isLoggedIn() {
		return userPool.getCurrentUser();
	}

	getUnauthCredentials() {
		return new Promise((resolve, rej) => {
			const _self = this;
			AWSSDK.config.update({
				region: _self.config.cognitoConfig.region,
				credentials: new AWSSDK.CognitoIdentityCredentials({
					IdentityPoolId: PoolData.IdentityPoolId,
				}),
			});
			resolve(AWSSDK.config.credentials);
		});
	}

	getAwsUnauthCredentials() {
		const _self = this;
		AWSSDK.config.update({
			region: _self.config.cognitoConfig.region,
			credentials: new AWSSDK.CognitoIdentityCredentials({
				IdentityPoolId: _self.config.cognitoConfig.unAuthIdentityPoolId,
			}),
		});
	}

	/**
	 * Logs out current user
	 * clears local storage and session storage
	 */
	logoutUser() {
		if (userPool.getCurrentUser() !== null) {
			userPool.getCurrentUser().signOut();
		}
		if (AWS.config.credentials && !localStorage.getItem('sso-type')) {
			AWS.config.credentials.clearCachedId();
			AWS.config.credentials = new AWS.CognitoIdentityCredentials({});
		}
		if (window[this.config.userLoginSession.sessionCheckIntervalVarName]) {
			clearInterval(window[this.config.userLoginSession.sessionCheckIntervalVarName]);
		}
		localStorage.clear();
		sessionStorage.clear();
		this.dataCarrier.reset();
		this.mfaService.fetchGlobalMfaStatus();
	}

	/**
	 * Cognito forgot password
	 * @param email
	 */
	forgotPassword(email, sendLink: boolean = false, returnUrl: string = '') {
		const userData = {
			Username: email,
			Pool: userPool,
		};
		const cognitoUser = new CognitoUser(userData);
		this.result = new Subject<any>();
		let clientMetadata = {};
		const utmParams = this.getUtmParameters();
		if (sendLink && utmParams) {
			clientMetadata = {
				sendLink: sendLink.toString(),
				returnUrl: returnUrl,
				...utmParams,
			};
		} else {
			clientMetadata = { sendLink: sendLink.toString(), returnUrl: returnUrl };
		}
		cognitoUser.forgotPassword(
			{
				onSuccess: result => {
					const error = { success: true, object: result };
					this.result.next(error);
				},
				onFailure: err => {
					console.error(`[${AuthService.name}][${this.forgotPassword.name}]`, 'Error on forgot password', email, err);
					const error = { success: false, object: err };
					this.result.error(error);
				},
			},
			clientMetadata
		);
	}

	/**
	 * Cognito reset password
	 * @param email
	 */
	resetPassword(userEmailID, verificationCode, newPassword) {
		const userData = {
			Username: userEmailID,
			Pool: userPool,
		};
		const cognitoUser = new CognitoUser(userData);
		this.result = new Subject<any>();
		cognitoUser.confirmPassword(verificationCode, newPassword, {
			onSuccess: () => {
				const error = { success: true };
				this.result.next(error);
			},
			onFailure: (err: Error) => {
				console.error(`[${AuthService.name}][${this.resetPassword.name}]`, 'Error on reset password', userEmailID, err);
				const error = { success: false, object: err };
				this.result.error(error);
			},
		});
	}

	getResult(): Observable<any> {
		return this.result.asObservable();
	}

	getSignUpResult(): Observable<any> {
		return this.signUpResult.asObservable();
	}

	/**
	 * Cognito update user password
	 * @param email
	 */
	updateUserPassword(oldPassword, newPassword) {
		const cognitoUser = userPool.getCurrentUser();
		if (cognitoUser != null) {
			// To get the session of current user
			const thisRef = this;
			cognitoUser.getSession(function (err, session) {
				if (err) {
					console.error(
						`[${AuthService.name}][${thisRef.updateUserPassword.name}][1]`,
						'Error updating user password',
						err
					);
				}
			});
		}
		this.result = new Subject<any>();
		cognitoUser.changePassword(oldPassword, newPassword, (err, res) => {
			if (err) {
				console.error(`[${AuthService.name}][${this.updateUserPassword.name}][2]`, 'Error updating user password', err);
				const error = { success: false, object: err };
				this.result.error(error);
			} else {
				const error = { success: true, object: '' };
				this.result.next(error);
			}
		});
	}

	/**
	 * Set AWS Credential for auto login using IdToken getting from SSO
	 * @param authToken - Token from SSO
	 */
	setAWSCredentials(authToken: any) {
		AWSSDK.config.region = this.config.cognitoConfig.region;
		const Logins = {};
		Logins[this.environment.getEnvironment().LOGINS_URL] = authToken.idToken.jwtToken;
		AWSSDK.config.credentials = new AWSSDK.CognitoIdentityCredentials({
			IdentityPoolId: PoolData.IdentityPoolId,
			Logins,
		});
	}

	/**
	 * Sets utm params in local storage
	 * @param params Utm Params
	 */
	setUtmParameters(params: any) {
		const utm = new UTMParameters();
		if (params.utm_source || params.utm_medium || params.utm_term || params.utm_content || params.utm_campaign) {
			utm.utmSource = params.utm_source;
			utm.utmMedium = params.utm_medium;
			utm.utmTerm = params.utm_term;
			utm.utmContent = params.utm_content;
			utm.utmCampaign = params.utm_campaign;
			localStorage.setItem('utm', JSON.stringify(utm));
		}
	}

	/**
	 * Gets Utm params object from local storage
	 */
	getUtmParameters() {
		if (localStorage.getItem('utm')) {
			const params = JSON.parse(localStorage.getItem('utm'));
			if (params) {
				const utmParams = new UtmParams();
				utmParams.utm_campaign = params.utmCampaign;
				utmParams.utm_content = params.utmContent;
				utmParams.utm_medium = params.utmMedium;
				utmParams.utm_source = params.utmSource;
				utmParams.utm_term = params.utmTerm;
				return utmParams;
			}
		}
	}
}
