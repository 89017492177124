import { config as awsConfig } from 'aws-sdk';
import { CredentialsAndIdentityId, CredentialsAndIdentityIdProvider } from '@aws-amplify/core';

export class AwsSdkCredentialsProvider implements CredentialsAndIdentityIdProvider {
	async getCredentialsAndIdentityId(): Promise<CredentialsAndIdentityId | undefined> {
		try {
			return new Promise<CredentialsAndIdentityId>((resolve, reject) => {
				// console.log('awsConfig.credentials:', );
				awsConfig.getCredentials(async error => {
					if (error) {
						reject(error);
						return;
					}
					// console.log('awsConfig.getCredentials:', credentials);
					// console.log('awsConfig.credentials:', awsConfig.credentials);
					resolve({
						credentials: {
							accessKeyId: awsConfig.credentials.accessKeyId,
							secretAccessKey: awsConfig.credentials.secretAccessKey,
							sessionToken: awsConfig.credentials.sessionToken,
						},
					});
				});
			});
		} catch (e) {
			console.error(`[${AwsSdkCredentialsProvider.name}][${this.getCredentialsAndIdentityId.name}]`, 'Error getting credentials:', e);
		}
	}

	// Implement this to clear any cached credentials and identityId.
	clearCredentialsAndIdentityId(): void { }
}
