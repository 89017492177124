import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { ValuePropositionComponent } from './value-proposition/value-proposition.component';
import { ValidationUtilityService } from './utils/validation.util/validation-utility.service';
import { HttpServices } from '../../services/core/http.service';
import { JwtHelper } from '../../services/core/jwt-helper.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { Config } from '../../config/config';
import { SlotTiersConfig } from '../../config/slot-tiers-config';

// Components
import { CustomRadioButtonComponent } from './ui-components/custom-radio-button/custom-radio-button.component';
import { RadioButtonSetComponent } from './ui-components/radio-button-set/radio-button-set.component';
import { Select3Component } from './ui-components/select3/select3.component';
import { DigitalBannerComponent } from './ui-components/digital-banner/digital-banner.component';

// Modules
import { MaterialModule } from './material/material.module';
import { ChartsModule } from './charts/charts.module';
// Directives
import { PhoneNumberDirective } from './directives/phone-number.directive';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
// Pipes
import { SortDataPipe } from './pipes/sort-data.pipe';
import { ModalComponent } from './ui-components/modal/modal.component';
import { AppointmentSlotCalendarComponent } from './appointment-slot-calendar/appointment-slot-calendar.component';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from './spinner/spinner.component';
import { SpinnerHideComponent } from './spinner-hide/spinner.component';

import { FormControlsGetterService } from './utils/form.util/form-control.service';
import { GeneralInfoService } from 'src/app/services/ha-new/general-info.service';
import { Environment } from '../../config/environment';
import { EheInfoModalComponent } from './ehe-modal/info-modal/ehe-info-modal.component';
import { EheVerificationModalComponent } from './ehe-modal/verification-modal/ehe-verification-modal.component';
import { ErrorMessages } from '../../config/error-messages';
import { AuthGuard } from '../../services/core/auth-guard.service';
import { DataCarrierService } from '../../services/core/data-carrier.service';

// Text Mask
import { IMaskModule } from 'angular-imask';
import { NgxSliderModule } from 'ngx-slider-v2';
import { ApiGatewayService } from '../../services/core/api-gateway.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ServiceRouter } from '../../services/core/http-service-router.service';
import { CodeVerificationModalComponent } from '../account/mat-modal/code-verification-modal.component';
import {
	GetKeysPipe,
	MaskSSNPipe,
	RemoveHTMLTagsPipe,
	TruncateLastNamePipe,
} from './../shared/pipes/sanitize-string.pipe';
import { MaskPhonePipe } from './../shared/pipes/sanitize-string.pipe';
import { CalendarService } from '../../services/core/add-calender.service';
import { ToastrModule } from 'ngx-toastr';
import { NgxEditorModule } from 'ngx-editor';
import { LanguageTranslateService } from '../../services/core/language-translate.service';
import { ResponsiveMenuComponent } from './responsive-menu/responsive-menu.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ProcedureSelectboxComponent } from './procedure-selectbox/procedure-selectbox.component';
import { EheAnalyticsDirective } from './ehe-analytics/ehe-analytics.directive';
import { DobComponent } from './dob/dob.component';
import { PrettyBytesPipe } from './pipes/pretty-bytes.pipe';
import { WhiteVerificationModalComponent } from './ehe-modal/white-verification-modal/white-verification-modal.component';
import { BrowserNotificationModalComponent } from './ehe-modal/browser-notification-modal/ehe-browser-notification-modal.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SsnMaskDirective } from './directives/ssn.directive';
import { OptimizeListenerDirective } from './directives/optimize-listener.directive';
import { ReportComponent } from './reports/report.component';
import { EncIdModalComponent } from '../account/mat-modal/enc-id-modal/enc-id-modal.component';
import { NonMatchedUserModalComponent } from '../dashboard/nonmatched-user-modal/nonmatched-user-modal.component';
import { RouteChangeConfirmationModalComponent } from './route-change-confirmation-modal/route-change-confirmation-modal.component';
import { AppointmentSlotBookedModalComponent } from './ehe-modal/appointment-slot-booked-modal/appointment-slot-booked-modal.component';
import { SimplifiedSchedulingGuard } from '../../services/core/simplified-scheduling.guard.service';
import { InventorySlotComponent } from './inventory-slot/inventory-slot.component';
import { DateHeaderComponent } from './date-header/date-header.component';
import { ScrollArrowComponent } from './scroll-arrow/scroll-arrow.component';
import { LocationAddressComponent } from './location-address/location-address.component';
import { MonthDisplayComponent } from './month-display/month-display.component';
import { CookieService } from 'ngx-cookie-service';
import { GoogleAnalyticsDirective } from './google-analytics/google-analytics.directive';
import { SimpleProgressBarComponent } from './simple-progress-bar/simple-progress-bar.component';
import { NameGreetingComponent } from './name-greeting/name-greeting.component';
import { WhiteOverlayComponent } from './ehe-modal/white-overlay/white-overlay.component';
import { ButtonLinkModalComponent } from './ehe-modal/button-link-modal/button-link-modal.component';
import { ButtonOutlineComponent } from './button-outline/button-outline.component';
import { ButtonCommonComponent } from './button-common/button-common.component';
import { ShowHidePasswordDirective } from './directives/show-hide-password.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { ToasterComponent } from './toaster/toaster.component';
import { WordPressOverlayComponent } from './modals/word-press-overlay/word-press-overlay.component';
import { MemberInfoConfirmationModalComponent } from './member-info-confirmation-modal/member-info-confirmation-modal.component';
import { FullOverlayComponent } from './ehe-modal/full-overlay/full-overlay.component';
import { UpdateProviderVideoModalComponent } from './update-provider-video-modal/update-provider-video-modal.component';
import { PasswordStrengthInfoComponent } from './password-strength-info/password-strength-info.component';
import { ENgxPrintComponent } from './ngx-print-block/e-ngx-print.component';
import { PvBottomBannerComponent } from './pv-bottom-banner/pv-bottom-banner.component';
import { MatCarouselComponent } from './carousel/carousel.component';
import { MatCarouselSlideComponent } from './carousel/carousel-slide/carousel-slide.component';
import { MfaInputScreenComponent } from './mfa-input-screen/mfa-input-screen.component';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';

(<any>window).dataCarrier = new DataCarrierService();

@Injectable()
export class MatCarouselHammerConfig extends HammerGestureConfig {
	override overrides = {
		pinch: { enable: false },
		rotate: { enable: false }
	};
}

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		MaterialModule,
		RouterModule,
		ReactiveFormsModule,
		ChartsModule,
		IMaskModule,
		NgxSliderModule,
		NgxSpinnerModule,
		PdfViewerModule,
		TranslateModule,
		ToastrModule.forRoot({
			preventDuplicates: true,
			newestOnTop: true,
		}),
		NgxEditorModule,
		NgxPermissionsModule,
		FormsModule,
		HammerModule,
	],
	declarations: [
		ValuePropositionComponent,
		HeaderComponent,
		FooterComponent,
		ErrorMessageComponent,
		CustomRadioButtonComponent,
		RadioButtonSetComponent,
		Select3Component,
		PhoneNumberDirective,
		NumbersOnlyDirective,
		ModalComponent,
		SortDataPipe,
		MaskSSNPipe,
		AppointmentSlotCalendarComponent,
		SpinnerComponent,
		EheInfoModalComponent,
		EheVerificationModalComponent,
		SpinnerHideComponent,
		CodeVerificationModalComponent,
		GetKeysPipe,
		MaskPhonePipe,
		ResponsiveMenuComponent,
		ProcedureSelectboxComponent,
		EheAnalyticsDirective,
		SsnMaskDirective,
		OptimizeListenerDirective,
		DobComponent,
		PrettyBytesPipe,
		WhiteVerificationModalComponent,
		BrowserNotificationModalComponent,
		ReportComponent,
		EncIdModalComponent,
		NonMatchedUserModalComponent,
		RouteChangeConfirmationModalComponent,
		AppointmentSlotBookedModalComponent,
		InventorySlotComponent,
		DateHeaderComponent,
		ScrollArrowComponent,
		LocationAddressComponent,
		MonthDisplayComponent,
		SimpleProgressBarComponent,
		NameGreetingComponent,
		GoogleAnalyticsDirective,
		WhiteOverlayComponent,
		ButtonLinkModalComponent,
		ButtonOutlineComponent,
		ButtonCommonComponent,
		ShowHidePasswordDirective,
		AutofocusDirective,
		ToasterComponent,
		WordPressOverlayComponent,
		TruncateLastNamePipe,
		MemberInfoConfirmationModalComponent,
		FullOverlayComponent,
		UpdateProviderVideoModalComponent,
		PasswordStrengthInfoComponent,
		ENgxPrintComponent,
		DigitalBannerComponent,
		PvBottomBannerComponent,
		RemoveHTMLTagsPipe,
		MatCarouselComponent,
		MatCarouselSlideComponent,
		MfaInputScreenComponent,
		SignaturePadComponent,
	],
	exports: [
		MfaInputScreenComponent,
		MaterialModule,
		HeaderComponent,
		GetKeysPipe,
		MaskPhonePipe,
		MaskSSNPipe,
		FooterComponent,
		ErrorMessageComponent,
		CustomRadioButtonComponent,
		PhoneNumberDirective,
		RadioButtonSetComponent,
		Select3Component,
		NumbersOnlyDirective,
		ModalComponent,
		AppointmentSlotCalendarComponent,
		SpinnerComponent,
		SpinnerHideComponent,
		EheInfoModalComponent,
		EheVerificationModalComponent,
		ChartsModule,
		IMaskModule,
		NgxSliderModule,
		CodeVerificationModalComponent,
		TranslateModule,
		ResponsiveMenuComponent,
		NgxEditorModule,
		ProcedureSelectboxComponent,
		EheAnalyticsDirective,
		SsnMaskDirective,
		OptimizeListenerDirective,
		DobComponent,
		PrettyBytesPipe,
		WhiteVerificationModalComponent,
		BrowserNotificationModalComponent,
		NgxPermissionsModule,
		ReportComponent,
		EncIdModalComponent,
		NonMatchedUserModalComponent,
		RouteChangeConfirmationModalComponent,
		AppointmentSlotBookedModalComponent,
		InventorySlotComponent,
		DateHeaderComponent,
		ScrollArrowComponent,
		LocationAddressComponent,
		MonthDisplayComponent,
		SimpleProgressBarComponent,
		NameGreetingComponent,
		GoogleAnalyticsDirective,
		WhiteOverlayComponent,
		ValuePropositionComponent,
		ButtonLinkModalComponent,
		ButtonOutlineComponent,
		ButtonCommonComponent,
		ShowHidePasswordDirective,
		AutofocusDirective,
		WordPressOverlayComponent,
		FullOverlayComponent,
		TruncateLastNamePipe,
		PasswordStrengthInfoComponent,
		ENgxPrintComponent,
		DigitalBannerComponent,
		PvBottomBannerComponent,
		RemoveHTMLTagsPipe,
		MatCarouselComponent,
		MatCarouselSlideComponent,
		SignaturePadComponent,
	],
	providers: [
		ValidationUtilityService,
		HttpServices,
		JwtHelper,
		FormControlsGetterService,
		Config,
		SlotTiersConfig,
		Environment,
		ErrorMessages,
		AuthGuard,
		ApiGatewayService,
		ServiceRouter,
		CalendarService,
		LanguageTranslateService,
		{ provide: DataCarrierService, useValue: (<any>window).dataCarrier },
		{ provide: HAMMER_GESTURE_CONFIG, useClass: MatCarouselHammerConfig },
		SimplifiedSchedulingGuard,
		CookieService,
		GeneralInfoService,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
	constructor(private languageTranslateService: LanguageTranslateService) { }
}
