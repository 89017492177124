import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationUtilityService } from '../../modules/shared/utils/validation.util/validation-utility.service';
import { AuthService } from '../../services/core/auth.service';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { SpinnerComponent } from '../../modules/shared/spinner/spinner.component';
import { AppUtil } from '../../utils/app.util';
import { Subscription } from 'rxjs';
import { AnalyticsBean } from '../../beans/analytics/analytics-module.bean';
import { Config } from '../../config/config';
import { EHEAndMeAuthService } from '../../services/core/eheandme-auth.service';
import { ProfileRelatedInfoBean } from '../../beans/account/account-module.bean';
import { ProfileService } from '../../services/core/profile.service';
import { MigrationStatusService } from '../../services/migration/migration-status.service';
import { FeedsService } from '../../services/dashboard/feeds.service';
import { AppointmentsService } from '../../services/scheduling/appointment/appointments.service';
import { DataCarrierService } from '../../services/core/data-carrier.service';
import { ModalService } from 'src/app/services/core/modal.service';

/**
 * Login Component
 */
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
	/** Sign In form */
	signInForm: FormGroup;
	/**
	 * Associated with create account link in Login page,
	 * default value - /registration
	 * in the case of Marketo  - /registration/embed/signup
	 */
	createAccountUrl: string = '/registration';
	/**
	 * Associated with create account link in Login page,
	 * default value - /forgot/password
	 * in the case of Marketo  - /embed/forgot-password
	 */
	forgotPasswordUrl: string = '/forgot/password';
	/** query param value denote where to return after login */
	returnUrl = '';
	/**
	 * List of subscriptions used in the component
	 * and this will unsubscribed on destroy
	 */
	subscriptions: Subscription[] = [];
	/**
	 *  url to redirect after clicking forgot username
	 */
	forgotUsernameUrl: string = '';
	/**
	 * flag to display error message or not
	 */
	displayErrMsg: boolean = false;
	/** Custom Analytics data for the component */
	analyticsData: AnalyticsBean;
	/** flag to show if profile not found error */
	profileNotFound: boolean = false;
	// Store the error message
	errMessage: string = '';
	@Input() externalError: string;

	/**
	 * Login component constructor
	 * @param authService Cognito related authentication service
	 * @param router Angular router
	 * @param spinner spinner Service
	 * @param activatedParams Angular activate route
	 * @param jwtService JWT Services
	 */
	constructor(
		private authService: AuthService,
		private router: Router,
		private spinner: SpinnerComponent,
		private activatedParams: ActivatedRoute,
		private config: Config,
		private eheandmeAuthService: EHEAndMeAuthService,
		private profileService: ProfileService,
		private migrationService: MigrationStatusService,
		private feedsService: FeedsService,
		private appointmentService: AppointmentsService,
		private dataCarrierService: DataCarrierService,
		private modalService: ModalService
	) {
		this.analyticsData = new AnalyticsBean();
		this.analyticsData.componentName = 'login';
		this.analyticsData.pageUrl = '/login';
		// Restricting user to use back browser button
		this.subscriptions.push(
			this.activatedParams.queryParams.subscribe(params => {
				/** getting the return URL from params */
				this.returnUrl = params.returnUrl ? decodeURI(params.returnUrl) : '';
				if (params.clearHistory) {
					window.onpopstate = function (event) {
						history.go(1);
					};
				}
				if (params.showErrorMsg) {
					this.profileNotFound = true;
				}
			})
		);
	}

	/**
	 * initializing the component and
	 * create the login form with email and password fields
	 */
	ngOnInit() {
		this.signInForm = new FormGroup({
			email: new FormControl(
				'',
				Validators.compose([
					Validators.required,
					Validators.minLength(2),
					Validators.maxLength(200),
					ValidationUtilityService.nameValidator,
				])
			),
			password: new FormControl('', Validators.compose([Validators.required])),
		});

		this.subscriptions.push(
			this.signInForm.get('password').valueChanges.subscribe(value => {
				if (this.signInForm.get('password').errors) {
					this.signInForm.get('password').setErrors({ passwordRequired: true });
				}
			})
		);

		/**
		 * if the page is loading inside marketo the createAccountUrl and forgotPasswordUrl
		 * have to update to their corresponding marketo route
		 */
		if (this.router.routerState.snapshot.url.includes('embed')) {
			this.createAccountUrl = '/registration/embed/signup';
			this.forgotPasswordUrl = '/embed/forgot-password';
		}
	}

	/**
	 * login to the application
	 */
	login() {
		this.externalError = null;
		this.analyticsData.id = 'login';
		this.analyticsData.redirectedTo = 'dashboard';
		this.analyticsData.placement = 'login screen';
		if (this.signInForm.valid) {
			const authCredentials = this.signInForm.getRawValue();
			this.spinner.startLoader();
			this.displayErrMsg = false;
			this.profileNotFound = false;
			if (AppUtil.isValidEmail(authCredentials.email)) {
				// Likely MyEHE Cognito Auth
				const email = AppUtil.getFormattedEmail(authCredentials.email);
				console.info("Only login component")
				this.authService.login(email, authCredentials.password);
				this.subscriptions.push(
					this.authService.getResult().subscribe(
						res => {
							this.spinner.stopLoader();
							console.log('LOGIN STATUS');
							this.getLoginStatus();
							/**
							 * handling the cases of marketo
							 * if the action requested from marketo, loading the page to full window
							 * otherwise loading to return url or by default dashboard
							 */
							if (this.router.routerState.snapshot.url.includes('embed')) {
								window.open(`${window.location.origin}/dashboard`, '_top');
							} else {
								if (this.returnUrl !== '') {
									this.spinner.startLoader();
									this.profileService.getProfileInfo().subscribe(
										(response: ProfileRelatedInfoBean) => {
											this.spinner.stopLoader();
											const profileInfo = response;
											if (profileInfo.profileInfo.userMatched) {
												if (this.returnUrl.includes('scheduling/create/appointment')) {
													sessionStorage.setItem('RETURN_URL', 'true');
												}
												this.router.navigateByUrl(this.returnUrl);
											} else {
												this.router.navigate(['dashboard']);
											}
										},
										err => {
											this.spinner.stopLoader();
										}
									);
								} else {
									this.spinner.startLoader();
									AppUtil.redirectToScheduling(
										this.router,
										this.feedsService,
										this.appointmentService,
										this.dataCarrierService
									);
								}
							}
						},
						error => {
							console.error(`[${LoginComponent.name}][${this.login.name}]`, 'Error on login ', email, error);
							this.errMessage =
								error.message === this.config.exceptions.cognito.ATTEMPTS_EXCEEDED &&
								this.config.exceptions.cognito.ATTEMPTS_EXCEEDED;
							if (error.code === this.config.exceptions.cognito.NOT_AUTHORIZED) {
								// Check migration status of the user when email/password combination is wrong
								this.checkMigrationStatus(email);
							} else {
								this.attemptEheAndMeAuth(authCredentials);
							}
						}
					)
				);
			} else {
				// EHE+Me Auth
				this.attemptEheAndMeAuth(authCredentials);
			}
		}
	}

	redirect(url: string): void {
		if (this.returnUrl !== '') {
			this.router.navigate([url], {
				queryParams: { returnUrl: this.returnUrl },
			});
		} else {
			this.router.navigate([url]);
		}
	}

	attemptEheAndMeAuth(authCredentials) {
		const email = AppUtil.getFormattedEmail(authCredentials.email);
		this.eheandmeAuthService.eheandmeLogin(email, authCredentials.password).subscribe(
			res => {
				this.spinner.stopLoader();
				this.checkMigrationStatus(email);
			},
			err => {
				console.error(`[${LoginComponent.name}][${this.attemptEheAndMeAuth.name}]`, 'An error occurred while authenticating ', err);
				this.spinner.stopLoader();
				this.displayErrMsg = true;
			}
		);
	}

	/**
	 * Checks the migration status of the user
	 * @param user - username/email
	 * @param navigate - flag to decide if user has to be redirected to ehe+me
	 */
	checkMigrationStatus(user: any = '') {
		this.spinner.startLoader();
		// EHE+Me Login succeeded, checking migration status of the user
		this.migrationService.getMigrationStatus(user).subscribe(
			response => {
				this.spinner.stopLoader();
				if (response.migrationStatus === 'Initiated') {
					// Initialized - Email exists on file - collect code and complete migration process
					const navigationExtras: NavigationExtras = {
						queryParams: {
							user: btoa(JSON.stringify(response)),
							returnUrl: this.returnUrl,
						},
					};
					this.router.navigate(['/migration/password'], navigationExtras);
				} else if (response.migrationStatus === 'Failed') {
					// Failed - No email on file - get email and complete migration process
					const navigationExtras: NavigationExtras = {
						queryParams: {
							user: btoa(JSON.stringify(response)),
							returnUrl: this.returnUrl,
						},
					};
					this.router.navigate(['/migration/email'], navigationExtras);
				} else if (response.migrationStatus === 'Completed') {
					// Completed - account has been migrated to myEHE
					this.displayErrMsg = true;
				} else {
					this.spinner.stopLoader();
					this.displayErrMsg = true;
				}
			},
			err => {
				this.spinner.stopLoader();
				this.displayErrMsg = true;
				console.error(`[${LoginComponent.name}][${this.checkMigrationStatus.name}]`, 'An error occurred while fetching migration status of the user ', err);
			}
		);
	}

	/**
	 * cleaning the component by unsubscribing the subscriptions used
	 */
	ngOnDestroy() {
		// prevent memory leak when component destroyed
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
		this.subscriptions = [];
	}

	/**
	 * Either shows/hides password hint
	 */
	showPasswordHint() {
		if (this.signInForm.get('password').touched && this.signInForm.get('password').errors) {
			return false;
		}
		return true;
	}

	getLoginStatus() {
		if (window[this.config.userLoginSession.sessionCheckIntervalVarName]) {
			clearInterval(window[this.config.userLoginSession.sessionCheckIntervalVarName]);
		}
		window[this.config.userLoginSession.sessionCheckIntervalVarName] = setInterval(() => {
			this.profileService.GetSessionInfo().subscribe(res => {
				if (res.message === 'Session Closed') {
					this.modalService.openSessionExpireModal(
						'SESSION EXPIRED',
						'You have an active session in another device. Please login to continue'
					);
					this.profileService.inactivateSession().subscribe(response => this.authService.logoutUser());
				}
			});
		}, this.config.userLoginSession.sessionCheckIntervalSeconds * 1000);
	}
}
