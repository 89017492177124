import { Injectable } from '@angular/core';
import { Config } from './config';

const config = new Config();
/** Error messages configuration */
@Injectable()
export class ErrorMessages {
	/** Error messages text */
	text = {
		userNotExistError:
			`We could not find your profile. <br> <br> <ul><li>Did you enter the email or username correctly?</li> <li>Is the email associated with your EHE Health benefit?</li> <li> Do you have an account? <a class="link" href="/registration">Create an account</a></li></ul>  <br> Need help? Call ${config.contactInfo.phone_prefix_1_dots} or email <a class="link" href= "mailto:${config.contactInfo.email_MemberServices}">${config.contactInfo.email_MemberServices}</a>.`,
		limitExceededError: 'Attempt limit exceeded',
		resetPasswordError: 'Some error has occurred while resetting the Password. Please try again later.',
		verificationCodeError: 'Invalid Verification Code',
		noData: 'No Data to display',
		invalidParameterError:
			'Oops! It looks like your record is locked. <br>Please contact us at 800.362.8671 or <a class="email-link" href="mailto:MemberServices@ehe.health">MemberServices@ehe.health</a> and we\'ll get this resolved right away.',
		invalidForgotPasswordPreCheck: `It looks likes something is not right, please check your details and try again!`
	};

	/** Error messages for no data */
	noData = {
		currentReferrals: 'You have no current referrals.',
		referralRequests: 'You have no current referral requests.',
		referralReady: 'You have no referrals ready.',
		appointmentRequests: 'You have no pending appointment requests.',
		confirmedAppointments: 'You have no confirmed appointments.',
	};
}
