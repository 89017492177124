<div class="content-box-wrapper">
	<div class="outer-wrap mfa-initial-screen" *ngIf="!isFirstTimeMfaSetup">
		<div class="content">
			<div style="padding: 0px 13px; padding-top: 15px; font-size: 15px">
				<p>Welcome, {{ firstName }}!</p>
				<p>
					For additional security measures, we have sent a six-digit code to
					<strong>{{ codeDeliveryDestination }}</strong>
					.
				</p>
				<p>Please enter the code to continue.</p>
			</div>

			<p
				*ngIf="showError"
				style="color: tomato; padding: 0px 13px; font-size: 13px; margin-bottom: -10px; margin-top: -10px">
				{{ errorMessage }}
			</p>

			<div class="otp-container">
				<input
					style="color: #464646"
					*ngFor="let box of otpBoxes; let i = index"
					type="text"
					maxlength="1"
					[(ngModel)]="otpValues[i]"
					(input)="onInput($event, i)"
					(keydown)="onKeydown($event, i)"
					(paste)="onPaste($event)"
					class="otp-box" />
			</div>
			<br />
			<div class="mb-3 px-3">
				<span class="link-color" (click)="toggleResendCode()">Resend Code</span><br />
				<p class="help-message" *ngIf="showResendCodeMessage">
					The code has been resent to the email address provided. Please check your spam folder if you don't see a
					message after few minutes.
				</p>
				<span class="link-color" (click)="toggleGetHelp()">Get Help</span>
				<p class="help-message" *ngIf="showHelpMessage">
					For further assistance, please contact Member Services at MemberServices&#64;ehe.health or 800-362-8671
					Monday-Thursday 8AM-8PM and Friday 8AM-6PM EST.
				</p>
			</div>
		</div>
		<div class="button-container d-flex justify-content-between mt-5 mb-3">
			<button
				mat-stroked-button
				class="button px-3 rounded-pill font-weight-bold"
				style="color: #0d3d60"
				(click)="goBack()">
				Back
			</button>
			<button class="btn btn-warning px-3 rounded-pill font-weight-bold" style="color: #0d3d60" (click)="submitCode()">
				Continue
			</button>
		</div>
	</div>
	<div class="outer-wrap mfa-success" style="display: flex" *ngIf="isFirstTimeMfaSetup">
		<div>
			<h2 class="my-4">Success!</h2>
			<p style="font-size: 15px">
				You can manage your login preferences any time from the
				<span class="link" (click)="dismissAction(true)">My Account</span>
				screen.
			</p>
		</div>
		<div class="button-container d-flex justify-content-end">
			<button
				class="btn btn-warning px-3 rounded-pill font-weight-bold"
				style="color: #0d3d60; bottom: 22px; right: 22px"
				(click)="dismissAction()">
				Dismiss
			</button>
		</div>
	</div>
</div>
