import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ValidationUtilityService } from '../../modules/shared/utils/validation.util/validation-utility.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/core/auth.service';
import { ErrorMessages } from '../../config/error-messages';
import { SpinnerComponent } from '../../modules/shared/spinner/spinner.component';
import { Config } from '../../config/config';
import { Subscription } from 'rxjs';
import { AnalyticsBean } from 'src/app/beans/analytics/analytics-module.bean';
import { AppUtil } from '../../utils/app.util';
import { ProfileRelatedInfoBean } from '../../beans/account/account-module.bean';
import { ProfileService } from '../../services/core/profile.service';
import { FeedsService } from '../../services/dashboard/feeds.service';
import { DataCarrierService } from '../../services/core/data-carrier.service';
import { AppointmentsService } from '../../services/scheduling/appointment/appointments.service';
import { passwordSpecialCharsValidatorRegExp } from 'src/app/constants/app.constants';
import { AUTH_COMPONENTS } from 'src/app/constants/scheduling.constants';

/**
 * Reset password component
 */
@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
	/** reset password form */
	resetPasswordForm: FormGroup;
	/** flag denote any error on reset password */
	ifAnyError: boolean = false;
	/** email id provided by user */
	userEmailID: string;
	/** flag holds,  code mis-match error */
	verificationCodeError: boolean = false;
	/** flag denote cognito error limitExceeded */
	ifLimitExceededError: boolean = false;
	/** flag denote, is it force reset password */
	isForceReset: boolean = false;
	/** flag to denote, is code resent */
	codeResent: boolean = false;
	/** Subscriptions array for storing all the subscriptions using **/
	subscriptions: Subscription[] = [];
	/** Analytics related data */
	analyticsData: AnalyticsBean;
	/** holding cognito error messages */
	errorMsg: string;
	/** Return Url */
	returnUrl: string = '';
	/** Holds verification code from redirect url */
	verificationCode: string = '';
	/** Holds the boolean value if its coming from link flow/regular forgot password flow */
	sendLink: boolean = false;
	/** Flag to hide password strength block on focus */
	hidePasswordStrengthBlock: boolean = true;
	/** Custom Password Special Characters Validator RegExp */
	passwordSpecialCharsValidatorRegExp: RegExp = passwordSpecialCharsValidatorRegExp;

	/**
	 * Reset password constructor
	 * @param router Angular Router
	 * @param authService Cognito related authorization Service
	 * @param fb Angular Form builder
	 * @param errorMessages Error messages
	 * @param spinner Spinner service
	 * @param modalService Info modal service
	 * @param serviceRouter Proxy service router
	 * @param config URL Configuration
	 * @param environment Environment
	 */
	constructor(
		public router: Router,
		private authService: AuthService,
		private fb: FormBuilder,
		private errorMessages: ErrorMessages,
		private spinner: SpinnerComponent,
		private config: Config,
		private profileService: ProfileService,
		private feedsService: FeedsService,
		private appointmentService: AppointmentsService,
		private dataCarrierService: DataCarrierService
	) {
		this.analyticsData = new AnalyticsBean();
		this.analyticsData.componentName = 'reset-password';
		this.analyticsData.pageUrl = '/reset/password';
	}

	/**
	 * on component initialization
	 *
	 * create reset password form & subscribing query params
	 */
	ngOnInit() {
		this.subscriptions.push(
			this.router.routerState.root.queryParams.subscribe(params => {
				// If password reset is forced, i.e., email updated on epms side
				if (params.resetPassword) {
					this.userEmailID = atob(params['email']);
					this.isForceReset = true;
				} else {
					if (params['email']) {
						this.userEmailID = atob(params['email']);
					}
					// When user comes through forgot password link
					if (params['link'] && params['link'] === 'true') {
						this.sendLink = true;
						this.verificationCode = params['code'];
						try {
							const expiry = atob(params['exp']);
							if (expiry) {
								if (Date.now() - 60 * 60 * 1000 > parseInt(expiry)) {
									this.router.navigate(['/forgot/password'], {
										queryParams: { linkExpired: true },
									});
								}
							}
						} catch (exception) {
							console.error(`[${ResetPasswordComponent.name}][${this.ngOnInit.name}]`, 'An error occurred while validating expiry time ', exception);
						}
					}
					this.returnUrl = params['returnUrl'] ? params['returnUrl'] : '';
				}
			})
		);
		this.generateResetPasswordForm();
	}

	/**
	 * Generate Reset password form,
	 * The form contains verificationCode, password, confirmPassword
	 */
	generateResetPasswordForm() {
		this.resetPasswordForm = this.fb.group(
			{
				verificationCode: new FormControl(
					this.verificationCode,
					Validators.compose([Validators.required, ValidationUtilityService.verificationCodeValidator])
				),
				password: new FormControl(
					'',
					Validators.compose([Validators.required, ValidationUtilityService.passwordValidator])
				),
				confirmPassword: new FormControl(
					'',
					Validators.compose([Validators.required, ValidationUtilityService.passwordValidator])
				),
			},
			{ validator: ValidationUtilityService.matchingPasswords.bind(this) }
		);
	}

	/**
	 * Request verification code to cognito while user choose to resent the verification code
	 */
	requestVerificationCode() {
		this.analyticsData.id = 'Reset password Resend Verification Code';
		this.analyticsData.redirectedTo = '/reset/password';
		this.analyticsData.placement = 'Reset password';
		if (this.userEmailID) {
			this.spinner.startLoader();
			this.authService.forgotPassword(this.userEmailID);
			this.subscriptions.push(
				this.authService.getResult().subscribe(
					res => {
						this.spinner.stopLoader();
						if (res.success) {
							this.codeResent = true;
							this.verificationCodeError = false;
							this.ifLimitExceededError = false;
							this.ifAnyError = false;
						}
					},
					error => {
						this.ifAnyError = true;
						this.errorMsg = '';
						if (error.object.code === this.config.exceptions.cognito.USER_NOT_FOUND) {
							this.errorMsg = this.errorMessages.text.userNotExistError;
						} else if (error.object.code === this.config.exceptions.cognito.LIMIT_EXCEEDED) {
							this.ifLimitExceededError = true;
							this.errorMsg = this.errorMessages.text.limitExceededError;
						} else {
							this.errorMsg = error.object.message;
						}
						this.spinner.stopLoader();
						console.error(`[${ResetPasswordComponent.name}][${this.requestVerificationCode.name}]`, 'Error on Reset password Resend Verification Code ', error);
					}
				)
			);
		} else {
			this.ifAnyError = true;
			this.errorMsg = this.errorMessages.text.resetPasswordError;
		}
	}

	/**
	 * Reset password from both cognito & EPMS
	 */
	resetPassword() {
		this.analyticsData.id = 'Reset password';
		this.analyticsData.redirectedTo = 'login';
		this.analyticsData.placement = 'Reset password';
		const resetPasswordFormDetails = this.resetPasswordForm.getRawValue();
		const newPassword = resetPasswordFormDetails.password;
		const verificationCode = resetPasswordFormDetails.verificationCode;
		if (this.resetPasswordForm.valid) {
			if (this.userEmailID) {
				this.spinner.startLoader();
				this.authService.resetPassword(this.userEmailID, verificationCode, newPassword);
				this.subscriptions.push(
					this.authService.getResult().subscribe(
						res => {
							this.spinner.stopLoader();
							if (res.success) {
								this.login();
								sessionStorage.setItem('forgotPassToaster', '1');
							}
						},
						err => {
							this.spinner.stopLoader();
							console.error(`[${ResetPasswordComponent.name}][${this.resetPassword.name}]`, 'Error during Password reset', this.userEmailID, err);
							if (
								err.object.code === this.config.exceptions.cognito.CODE_MISMATCH ||
								err.object.code === this.config.exceptions.cognito.CODE_EXPIRED
							) {
								this.errorMsg = this.errorMessages.text.verificationCodeError;
								this.verificationCodeError = true;
								if (this.sendLink) {
									this.router.navigate(['/forgot/password'], {
										queryParams: { linkExpired: true },
									});
								}
							} else if (err.object.code === this.config.exceptions.cognito.LIMIT_EXCEEDED) {
								this.errorMsg = this.errorMessages.text.limitExceededError;
								this.verificationCodeError = false;
								this.ifLimitExceededError = true;
							} else {
								this.ifAnyError = true;
								this.errorMsg = this.errorMessages.text.resetPasswordError;
							}
						}
					)
				);
			} else {
				this.ifAnyError = true;
				this.errorMsg = this.errorMessages.text.resetPasswordError;
			}
		} else {
			this.resetPasswordForm.get('verificationCode').markAsTouched();
			this.resetPasswordForm.get('password').markAsTouched();
			this.resetPasswordForm.get('confirmPassword').markAsTouched();
		}
	}

	/**
	 * Clean the component
	 */
	ngOnDestroy() {
		// prevent memory leak when component destroyed
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
		this.subscriptions = [];
	}

	checkIfConfirmPasswordIsValid() {
		if (this.resetPasswordForm.get('password').hasError('invalidPassword')) {
			if (
				this.resetPasswordForm.get('confirmPassword').errors &&
				!this.resetPasswordForm.get('confirmPassword').hasError('required')
			) {
				this.resetPasswordForm.get('confirmPassword').setErrors({ invalidPassword: true });
			}
		} else {
			if (
				this.resetPasswordForm.get('confirmPassword').errors &&
				!this.resetPasswordForm.get('confirmPassword').hasError('required')
			) {
				this.resetPasswordForm.get('confirmPassword').setErrors({ mismatchedPasswords: true });
			}
		}
	}

	/**
	 * Login the user immediately after creating account
	 */
	login() {
		this.spinner.startLoader();
		console.info("reset password component")
		this.authService.login(this.userEmailID, this.resetPasswordForm.getRawValue().password, AUTH_COMPONENTS.RESET_COMPONENT);
		this.subscriptions.push(
			this.authService.getResult().subscribe(response => {
				if (this.router.routerState.snapshot.url.includes('embed')) {
					this.spinner.stopLoader();
					window.open(`${window.location.origin}/dashboard`, '_top');
				} else {
					if (this.returnUrl !== '') {
						this.profileService.getProfileInfo().subscribe(
							(res: ProfileRelatedInfoBean) => {
								this.spinner.stopLoader();
								const profileInfo = res;
								if (profileInfo.profileInfo.userMatched) {
									if (this.returnUrl.includes('scheduling/create/appointment')) {
										sessionStorage.setItem('RETURN_URL', 'true');
									}
									this.router.navigateByUrl(`${this.returnUrl}`);
								} else {
									this.router.navigate(['dashboard']);
								}
							},
							err => {
								this.spinner.stopLoader();
							}
						);
					} else {
						AppUtil.redirectToScheduling(
							this.router,
							this.feedsService,
							this.appointmentService,
							this.dataCarrierService
						);
					}
				}
			})
		);
	}
}
