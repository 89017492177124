import { IMaskModule } from 'angular-imask';
import { NgxPermissionsModule } from 'ngx-permissions';

import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigService } from './config/app.config';
import { Environment } from './config/environment';
import { MaintenanceComponent } from './modules/shared/maintenance/maintenance.component';
import { MaterialModule } from './modules/shared/material/material.module';
import { PwcLandingPageComponent } from './modules/pwc-landing-page/pwc-landing-page.component';
import { SharedModule } from './modules/shared/shared.module';
import { SpinnerHideComponent } from './modules/shared/spinner-hide/spinner.component';
import { SpinnerComponent } from './modules/shared/spinner/spinner.component';
import { TutorialModule } from './modules/tutorial/tutorial.module';
import { ImmunizationComponent } from './modules/wellness/immunization/immunization.component';
import { VaxPassCardComponent } from './modules/wellness/vax-pass/vax-pass-card/vax-pass-card.component';
import { VaxPassComponent } from './modules/wellness/vax-pass/vax-pass.component';
import { VaxStatusComponent } from './modules/wellness/vax-pass/vax-status/vax-status.component';
import { VaxpassConsentFormComponent } from './modules/wellness/vax-pass/vaxpass-consent-form/vaxpass-consent-form.component';
import { AuthGuard } from './services/core/auth-guard.service';
import { AuthService } from './services/core/auth.service';
import { FeatureFlagGuardService } from './services/core/feature-flag-guard.service';
import { ProfileService } from './services/core/profile.service';
import { PulseVirtualGuard } from './services/core/pulse-virtual-guard.service';
import { SimplifiedSchedulingGuard } from './services/core/simplified-scheduling.guard.service';
import { TitleService } from './services/core/title.service';
import { FindAppointmentService } from './services/scheduling/find-appointment/find-appointment.service';
import { ActivateAccountComponent } from './views/activate-account/activate-account.component';
import { BaseForgotPasswordComponent } from './views/base-forgot-password/base-forgot-password.component';
import { BaseResetPasswordComponent } from './views/base-reset-password/base-reset-password.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { HomeComponent } from './views/home/home.component';
import { LoginScreenVariationComponent } from './views/login-screen-variation/login-screen-variation.component';
import { LoginSimplifiedComponent } from './views/login-simplified/login-simplified.component';
import { LoginComponent } from './views/login/login.component';
import { MigrationModule } from './views/migration/migration.module';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { SimplifiedFindAppointmentService } from './services/scheduling/simplified-find-appointment/simplified-find-appointment.service';

const appInitializerFn = (appConfig: AppConfigService) => {
	return () => {
		return appConfig.load();
	};
};

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		LoginSimplifiedComponent,
		ForgotPasswordComponent,
		ResetPasswordComponent,
		MaintenanceComponent,
		HomeComponent,
		BaseForgotPasswordComponent,
		BaseResetPasswordComponent,
		ActivateAccountComponent,
		PwcLandingPageComponent,
		VaxStatusComponent,
		VaxPassComponent,
		VaxpassConsentFormComponent,
		VaxPassCardComponent,
		ImmunizationComponent,
		LoginScreenVariationComponent,
		],
	imports: [
		BrowserModule,
		AppRoutingModule,
		SharedModule,
		NoopAnimationsModule,
		ReactiveFormsModule,
		MaterialModule,
		IMaskModule,
		HttpClientModule,
		BrowserAnimationsModule,
		NgxPermissionsModule.forRoot(), // load permissions module globally to work
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		TutorialModule,
		NoopAnimationsModule,
		MigrationModule,
		MatPasswordStrengthModule.forRoot(),
		MatTabsModule,
	],
	providers: [
		TitleService,
		AuthService,
		AuthGuard,
		FeatureFlagGuardService,
		AppConfigService,
		ProfileService,
		Environment,
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFn,
			deps: [AppConfigService],
			multi: true,
		},
		PulseVirtualGuard,
		SpinnerComponent,
		SpinnerHideComponent,
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		SimplifiedSchedulingGuard,
		{ provide: FindAppointmentService, useClass: FindAppointmentService },
		{ provide: SimplifiedFindAppointmentService, useClass: SimplifiedFindAppointmentService },
		{
			provide: MAT_SELECT_CONFIG,
			useValue: { panelWidth: null },
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(titleService: TitleService) {
		titleService.init();
	}
}
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}
