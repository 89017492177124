<div class="content-box-wrapper" *ngIf="(displayMfaInputScreen | async) !== true">
	<div class="content-box form-wrapper">
		<mat-tab-group
			[disableRipple]="true"
			[selectedIndex]="defaultTab"
			(selectedTabChange)="tabChange($event)"
			appGoogleAnalytics
			[eventData]="eventData"
			dynamicHeight>
			<mat-tab label="{{ bookIsOrder ? 'Order Your Kit' : 'Book Your Exam' }}" tabindex="1">
				<div id="formContainer" *ngIf="!bsSecondError">
					<p
						class="signInCopy"
						[hidden]="displayErrMsg"
						[translate]="'registration.login.descriptions.bookingSimplified'">
						Enter your information below to take the first step on your preventive health journey.
					</p>
					<p class="align-center inline-error-message text-14" *ngIf="bsErrorResponse.length > 0 && displayErrMsg">
						{{ bsErrorResponse }} <br /><br /><span>Reference Number: 2</span>
					</p>
					<form [formGroup]="bookingSimplified" id="bookingSimplified">
						<mat-grid-list cols="1" rowHeight="4:1">
							<mat-grid-tile>
								<mat-form-field class="form-group">
									<mat-label>{{ 'registration.create-account.form.first-name' | translate }}</mat-label>
									<input matInput formControlName="firstName" />
									<mat-error>
										<app-error-message [control]="'firstName'"></app-error-message>
									</mat-error>
								</mat-form-field>
							</mat-grid-tile>
							<mat-grid-tile>
								<mat-form-field class="form-group">
									<mat-label>{{ 'registration.create-account.form.last-name' | translate }}</mat-label>
									<input matInput formControlName="lastName" />
									<mat-error>
										<app-error-message [control]="'lastName'"></app-error-message>
									</mat-error>
								</mat-form-field>
							</mat-grid-tile>
							<mat-grid-tile>
								<mat-form-field class="form-group">
									<mat-label>{{ 'registration.create-account.form.dob' | translate }}</mat-label>
									<input
										(focusout)="changeHint()"
										matInput
										formControlName="dateOfBirth"
										[imask]="dobMask.iMaskArgs"
										[unmask]="dobMask.unmask" />
									<mat-hint *ngIf="showHint">{{ dateFormat }}</mat-hint>
									<mat-error>
										<app-error-message [control]="'dateOfBirth'"></app-error-message>
									</mat-error>
								</mat-form-field>
							</mat-grid-tile>
							<mat-grid-tile class="overflow-visible">
								<app-button-common
									appEheAnalytics
									[data]="{
										pageUrl: '/login',
										redirectedTo: '/book',
										componentName: 'login',
										id: 'Book My Exam',
										placement: 'login screen'
									}"
									appGoogleAnalytics
									[eventData]="{
										eventCategory: 'Button',
										eventAction: 'Click-BookYourExamButton',
										eventLabel: 'Signin - Check Eligibility'
									}"
									[buttonTextKey]="
										bookIsOrder ? 'registration.login.form.next' : 'registration.login.form.check-eligibility'
									"
									(click)="loadBookingSimplified()">
								</app-button-common>
							</mat-grid-tile>
						</mat-grid-list>
					</form>
				</div>
				<div id="secondError" *ngIf="bsSecondError">
					<p class="align-center inline-error-message text-14">
						We could not confirm your eligibility to book a new exam. Please
						<span
							style="color: #014484; text-decoration: underline; cursor: pointer"
							(click)="this.bsSecondError = false"
							>try again</span
						>
						or call 888.672.8172 to book your exam.
					</p>
				</div>
			</mat-tab>
			<mat-tab label="Sign In" tabindex="2">
				<div>
					<div
						class="signInCopy text-16"
						[translate]="'registration.login.descriptions.signIn'"
						*ngIf="!(displayErrMsg || signInMessage || profileNotFound)">
						Stay up to date on your preventive health with access to health records, appointment scheduling and
						post-exam care.
					</div>
					<div
						class="align-center inline-error-message text-16"
						*ngIf="externalError || displayErrMsg || profileNotFound">
						<div *ngIf="externalError">
							<span>{{ externalError }}</span>
						</div>
						<ng-container *ngIf="!externalError">
							<div *ngIf="errMessage && displayErrMsg">
								<span [translate]="'registration.login.errors.sign-in-error.attempt-exceed'">
									Password attempts exceeded. Your account has been temporarily locked for security reasons.
								</span>
							</div>
							<div *ngIf="!errMessage">
								<span [translate]="'registration.login.errors.sign-in-error.line-1'">
									We couldn't find your EHE Health profile. Try signing in with any email that may be associated with
									your account.
								</span>
							</div>
							<div class="pt-2">
								<span
									class="link"
									[routerLink]="['/forgot/password']"
									[translate]="'registration.login.errors.sign-in-error.forgot-password-link'">
									Forgot email/username or password?
								</span>
							</div>
						</ng-container>
					</div>
					<div *ngIf="signInMessage" class="align-center inline-error-message text-16">{{ signInMessage }}</div>
				</div>
				<form [formGroup]="signInForm">
					<mat-grid-list cols="1" rowHeight="fit" class="grid-height">
						<mat-grid-tile>
							<mat-form-field class="form-group">
								<mat-label>{{ 'registration.login.form.email' | translate }}</mat-label>
								<input matInput formControlName="email" />
								<mat-error>
									<app-error-message [control]="'email'"></app-error-message>
								</mat-error>
							</mat-form-field>
						</mat-grid-tile>
						<mat-grid-tile>
							<mat-form-field class="form-group password-form">
								<mat-label>{{ 'registration.login.form.password' | translate }}</mat-label>
								<input matInput formControlName="password" type="password" autocomplete="off" appShowHidePassword />
								<mat-error>
									<app-error-message [control]="'password'"></app-error-message>
								</mat-error>
								<mat-hint
									*ngIf="showPasswordHint()"
									class="password-hint"
									[translate]="'registration.login.descriptions.password'"
									>Password is case-sensitive and contains special characters</mat-hint
								>
							</mat-form-field>
						</mat-grid-tile>
						<mat-grid-tile>
							<div class="align-center margin-top-20">
								<app-button-common
									appEheAnalytics
									[data]="analyticsData"
									appGoogleAnalytics
									[eventData]="{
										eventCategory: 'Button',
										eventAction: 'Click-SignInButton',
										eventLabel: 'Signin - Sign In Button'
									}"
									[buttonTextKey]="'registration.login.form.submit-button'"
									(clickEvent)="login()">
								</app-button-common>
							</div>
						</mat-grid-tile>
					</mat-grid-list>
				</form>

				<div class="align-center margin-top-20">
					<app-button-outline
						appEheAnalytics
						[data]="{
							pageUrl: '/login',
							redirectedTo: '/registration',
							componentName: 'login',
							id: 'Create My Account',
							placement: 'login screen'
						}"
						[googleAnalyticsData]="{
							eventCategory: 'Link',
							eventAction: 'Click-CreateAccount',
							eventLabel: 'Signin - Create Account Link'
						}"
						[buttonTextKey]="'registration.login.links.create-account'"
						(clickEvent)="redirect(createAccountUrl)">
					</app-button-outline>
				</div>

				<div class="align-center margin-top-20">
					<a
						id="forgotEmailPasswordLink"
						appEheAnalytics
						[data]="{
							pageUrl: '/login',
							redirectedTo: '/forgot/password',
							componentName: 'login',
							id: 'Forgot Password',
							placement: 'login screen'
						}"
						appGoogleAnalytics
						[eventData]="{
							eventCategory: 'Link',
							eventAction: 'Click-ForgotPassword',
							eventLabel: 'Signin - Forgot Password Link'
						}"
						[translate]="'registration.login.links.forgot-password'"
						(click)="redirect(forgotPasswordUrl)">
						Forgot email/username or password?
					</a>
				</div>
			</mat-tab>
		</mat-tab-group>
	</div>
</div>
<!-- <div class="" *ngIf="(displayMfaInputScreen | async)">
	<div class="">
		 <app-mfa-input-screen></app-mfa-input-screen>
	</div>
</div> -->
