import { Component, OnInit } from '@angular/core';
import { MfaService } from 'src/app/services/core/mfa.service';

@Component({
	selector: 'app-mfa-input-screen',
	templateUrl: './mfa-input-screen.component.html',
	styleUrls: ['./mfa-input-screen.component.scss'],
})
export class MfaInputScreenComponent implements OnInit {
	mfaCode: string = '';
	codeDeliveryDestination: string = '';
	firstName: string = '';
	codeArray = new Array(6);
	showError: boolean = false;
	errorMessage: string;
	isFirstTimeMfaSetup: boolean = false;
	otpBoxes = Array(6).fill(0); // 6 boxes for the OTP
	otpValues: string[] = Array(6).fill(''); // Store each box value
	otpCode: string = ''; // Concatenate the code here

	showResendCodeMessage = false;
	showHelpMessage = false;

	constructor(private mfaService: MfaService) { }

	ngOnInit(): void {
		this.mfaService.displayMfaErrorMessage(false, '');
		this.mfaService.showMfaErrorMessage.subscribe((value: boolean) => {
			if (value) {
				this.otpValues = Array(6).fill('');
				this.otpCode = '';
			}
			this.showError = value;
		});

		this.mfaService.codeDeliveryDestination.subscribe((value: string) => {
			this.codeDeliveryDestination = value;
		});

		// Subscribe to mfaErrorMessage
		this.mfaService.mfaErrorMessage.subscribe((message: string) => {
			this.errorMessage = message;
		});

		this.mfaService.userFirstName.subscribe((value: string) => {
			this.firstName = value;
		});

		this.mfaService.mfaActivatedAt.subscribe((value: string) => {
			this.isFirstTimeMfaSetup = value === 'mfaSet'
		});
	}

	submitCode() {
		if (this.otpCode) {
			this.mfaService.setMfaCode("CONTINUE;" + this.otpCode);
		}
	}

	goBack() {
		console.info('MFA INPUT -> BACK Button clicked!');
		this.mfaService.toggleDisplayMfaInputScreen(false);
	}

	dismissAction(toPreferences: boolean = false) {
		if (toPreferences) {
			sessionStorage.setItem('returnUrlOverride', '/account#mfa-settings');
		}
		this.mfaService.toggleDisplayMfaSuccessScreen('dismissed');
	}

	onInput(event: Event, index: number) {
		const input = event.target as HTMLInputElement;
		const value = input.value;

		// Only allow digits (0-9)
		if (!/^\d$/.test(value)) {
			input.value = ''; // Clear any non-numeric input
			return;
		}

		// Update the otpValues array
		this.otpValues[index] = value;

		// Update the concatenated code
		this.otpCode = this.otpValues.join('');

		// Move to the next box if there is one
		if (index < this.otpBoxes.length - 1) {
			const nextBox = document.querySelectorAll<HTMLInputElement>('.otp-box')[index + 1];
			if (nextBox) {
				nextBox.focus(); // Focus the next input box
			}
		}
	}

	onKeydown(event: KeyboardEvent, index: number) {
		const input = event.target as HTMLInputElement;

		// Handle backspace
		if (event.key === 'Backspace') {
			if (!this.otpValues[index] && index > 0) {
				const prevBox = document.querySelectorAll<HTMLInputElement>('.otp-box')[index - 1];
				prevBox?.focus();
			} else {
				this.otpValues[index] = '';
			}
			this.otpCode = this.otpValues.join('');

		}
	}

	onPaste(event: ClipboardEvent) {
		const pasteData = event.clipboardData?.getData('text') || '';

		// Extract only the first six digits from the pasted data
		const digits = pasteData.match(/\d/g)?.slice(0, 6) || []; // Get only digits and limit to 6

		// Update otpValues array with the first six digits
		this.otpValues = digits;

		// Update the concatenated code
		this.otpCode = this.otpValues.join('');

		// Focus on the last box that has been filled
		let lastFilledIndex = this.otpValues.findIndex(value => value === '') !== -1 ?
			this.otpValues.findIndex(value => value === '') - 1 :
			this.otpValues.length - 1;

		const lastBox = document.querySelectorAll<HTMLInputElement>('.otp-box')[lastFilledIndex];
		lastBox?.focus(); // Focus the last filled input box

		// Prevent default paste behavior
		event.preventDefault();
	}

	toggleResendCode() {
		this.showResendCodeMessage = true;
		this.mfaService.setMfaCode('RESEND');
		this.otpValues = Array(6).fill('');
		this.otpCode = '';
		this.mfaService.displayMfaErrorMessage(false, '');
	}

	toggleGetHelp() {
		this.showHelpMessage = !this.showHelpMessage;
	}

}
