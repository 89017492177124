import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

/**
 * JWT Helper Service
 */
@Injectable({
	providedIn: 'root',
})
export class JwtHelper {
	/**
	 * get user name from token
	 */
	getUsername(): any {
		const decodedToken = this.getDecodedToken();
		return decodedToken ? decodedToken.name : undefined;
	}

	/**
	 * get emps pid from token
	 */
	getEpmsId(): string | undefined {
		const decodedToken = this.getDecodedToken();
		return decodedToken ? decodedToken['custom:epms-p-id'] : undefined;
	}

	/**
	 * get decoded token from token
	 */
	getDecodedToken(): any {
		if (sessionStorage.getItem('auth')) {
			const jwtHelper = new JwtHelperService();
			const decodedToken = jwtHelper.decodeToken(JSON.parse(sessionStorage.getItem('auth')).idToken.jwtToken);
			return decodedToken;
		}
	}

	decodeToken(token: string): any {
		const jwtHelper = new JwtHelperService();
		const decodedToken = jwtHelper.decodeToken(token);
		return decodedToken;
	}
}
