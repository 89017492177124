<div class="auth-content-wrapper">
	<app-value-proposition></app-value-proposition>
</div>

<div class="content-box form-wrapper">
	<div class="align-center text-28" *ngIf="!forgotUserEmailSent && !invalidEmail">
		<span id="headerText" tabindex="0" [translate]="'registration.forgot-password.title'">
			Forgot Email/Username or Password?
		</span>
	</div>

	<div *ngIf="successMessage && !forgotUserEmailSent">
		<div class="default text-16">
			<div tabindex="0" class="pt-3" *ngIf="successMessage" [innerHTML]="successMessage"></div>
			<div class="col-12">
				<div class="center">
					<button
						class="button button-medium"
						mat-button
						appEheAnalytics
						[data]="{
							pageUrl: '/forgot/password',
							redirectedTo: '/login',
							componentName: 'forgot-password',
							id: 'Back',
							placement: 'Forgot password'
						}"
						[routerLink]="['/']"
						[translate]="'registration.forgot-password.links.back'">
						Back
					</button>
				</div>
			</div>
		</div>
	</div>

	<div *ngIf="forgotUserEmailSent">
		<div class="align-center text-28" tabindex="0" [translate]="'registration.forgot-password.check-email'">
			Check your email!
		</div>
		<div tabindex="0" class="pt-3 ul-align-left" *ngIf="successMessage" [innerHTML]="successMessage"></div>
		<div class="col-12">
			<div class="center">
				<button
					class="button button-medium"
					mat-button
					appEheAnalytics
					[data]="{
						pageUrl: '/forgot/password',
						redirectedTo: '/login',
						componentName: 'forgot-password',
						id: 'Back',
						placement: 'Forgot password'
					}"
					[routerLink]="['/']"
					[translate]="'registration.forgot-password.links.back'">
					Back
				</button>
			</div>
		</div>
	</div>

	<div *ngIf="!successMessage && !forgotUserEmailSent">
		<div class="col-12 margin-top-30 align-center" *ngIf="!invalidAttempt">
			<div *ngIf="errorMsg" tabindex="0" class="inline-error-message ul-align-left" [innerHTML]="errorMsg"></div>
		</div>

		<div class="col-12 margin-top-30 align-center" *ngIf="limitExceededError">
			<button
				class="button button-medium"
				mat-button
				appEheAnalytics
				[data]="{
					pageUrl: '/forgot/password',
					redirectedTo: '/login',
					componentName: 'forgot-password',
					id: 'Back to Login',
					placement: 'Forgot password'
				}"
				appGoogleAnalytics
				[eventData]="{
					eventCategory: 'Button',
					eventAction: 'ForgotEmail - Back To SignIn',
					eventLabel: 'Back to Sign In'
				}"
				(click)="returnToLogin()"
				[translate]="'registration.forgot-password.links.back'">
				Back
			</button>
		</div>

		<div
			class="col-12 margin-top-30 radio-group-wrapper"
			*ngIf="numOfFailures < 2 && !limitExceededError && !invalidEmail">
			<mat-radio-group [formControl]="selectedRadio" aria-label="Select an option">
				<mat-radio-button
					value="forgot-password"
					appGoogleAnalytics
					(change)="toggleRadio()"
					[eventData]="{
						eventCategory: 'Radio',
						eventAction: 'Radio Button - Forgot My Password',
						eventLabel: 'Forgot My Password'
					}"
					attr.aria-label="{{ 'registration.forgot-password.radio.forgot-password' | translate }}">
					<span [translate]="'registration.forgot-password.radio.forgot-password'">Forgot My Password</span>
				</mat-radio-button>
				<mat-radio-button
					value="forgot-email"
					appGoogleAnalytics
					(change)="toggleRadio()"
					[eventData]="{
						eventCategory: 'Radio',
						eventAction: 'Radio Button - Forgot My Email/Username',
						eventLabel: 'Forgot My Email/Username'
					}"
					attr.aria-label="{{ 'registration.forgot-password.radio.forgot-email' | translate }}">
					<span [translate]="'registration.forgot-password.radio.forgot-email'">Forgot My Email/Username</span>
				</mat-radio-button>
			</mat-radio-group>
		</div>

		<div class="col-12" *ngIf="selectedRadio.value === 'forgot-password' && !limitExceededError && !invalidEmail">
			<div id="textContent">
				<span
					tabindex="0"
					class="align-center"
					*ngIf="linkExpired && !(successMessage || ifAnyError)"
					[translate]="'registration.forgot-password.link-expired'">
					The verification link has expired. Please enter your email or username to resend a verification email.
				</span>
			</div>

			<div class="margin-top-20" [formGroup]="requestVerificationCodeForm">
				<div *ngIf="numOfFailures < 2">
					<div class="row">
						<div class="col-12">
							<mat-form-field class="form-group">
								<mat-label>{{ 'registration.forgot-password.form.first-name' | translate }}</mat-label>
								<input
									matInput
									formControlName="firstName"
									(blur)="inputBlurMarkUntouched(requestVerificationCodeForm, 'firstName')" />
								<mat-error>
									<app-error-message [control]="'firstName'"></app-error-message>
								</mat-error>
							</mat-form-field>
						</div>
						<div class="col-12">
							<mat-form-field class="form-group">
								<mat-label>{{ 'registration.forgot-password.form.last-name' | translate }}</mat-label>
								<input
									matInput
									formControlName="lastName"
									(blur)="inputBlurMarkUntouched(requestVerificationCodeForm, 'lastName')" />
								<mat-error>
									<app-error-message [control]="'lastName'"></app-error-message>
								</mat-error>
							</mat-form-field>
						</div>
						<div class="col-12">
							<mat-form-field class="form-group">
								<mat-label>{{ 'registration.forgot-password.form.dob' | translate }}</mat-label>
								<input
									matInput
									formControlName="dateOfBirth"
									[imask]="dobMask.iMaskArgs"
									(blur)="inputBlurMarkUntouched(requestVerificationCodeForm, 'dateOfBirth')" />
								<mat-hint>{{ dateFormat }}</mat-hint>
								<mat-error>
									<app-error-message control="dateOfBirth"></app-error-message>
								</mat-error>
							</mat-form-field>
							<mat-form-field class="form-group">
								<mat-label>{{ 'registration.forgot-password.form.email' | translate }}</mat-label>
								<input
									matInput
									formControlName="email"
									type="text"
									(blur)="inputBlurMarkUntouched(requestVerificationCodeForm, 'email')" />
								<mat-error>
									<app-error-message [control]="'email'"></app-error-message>
								</mat-error>
							</mat-form-field>
						</div>
					</div>
				</div>

				<div class="d-flex justify-content-between align-items-center mt-4">
					<button
						id="forgotEmailPasswordLink"
						class="button secondary-btn basic button-medium"
						mat-stroked-button
						appEheAnalytics
						[data]="{
							pageUrl: '/forgot/password',
							redirectedTo: '/login',
							componentName: 'forgot-password-link',
							id: 'Back to Login - Link',
							placement: 'Forgot password - link'
						}"
						appGoogleAnalytics
						[eventData]="{
							eventCategory: 'Link',
							eventAction: 'Forgot Password- Back To SignIn',
							eventLabel: 'Back to Sign In'
						}"
						(click)="returnToLogin()">
						<span [translate]="'registration.forgot-password.links.back'">Back</span>
					</button>
					<button
						*ngIf="numOfFailures === 2"
						class="button button-medium"
						mat-button
						appEheAnalytics
						[data]="{
							pageUrl: '/forgot/password',
							redirectedTo: '/forgot/password',
							componentName: 'try-again-button',
							id: 'Try Again Button',
							placement: 'Try Again - button'
						}"
						appGoogleAnalytics
						[eventData]="{
							eventCategory: 'Button',
							eventAction: 'Forgot Password - Try Again',
							eventLabel: 'Try Again'
						}"
						(click)="clearPage()">
						<span [translate]="'registration.forgot-password.links.try-again'">Try Again</span>
					</button>
					<button
						*ngIf="numOfFailures < 2"
						class="button button-medium"
						mat-button
						appEheAnalytics
						[data]="analyticsData"
						(click)="validateUserStatus()"
						appGoogleAnalytics
						[eventData]="{
							eventCategory: 'Button',
							eventAction: 'Forgot Password - Next',
							eventLabel: 'Next'
						}">
						<span [translate]="'registration.forgot-password.form.submit-button'">Next</span>
					</button>
				</div>
			</div>
		</div>

		<div class="col-12" *ngIf="selectedRadio.value === 'forgot-email'">
			<div class="col-12 margin-top-20 align-center" *ngIf="invalidAttempt !== 4 && !invalidEmail">
				<div *ngIf="errorMsg" tabindex="0" class="inline-error-message pb-3" [innerHTML]="errorMsg"></div>
			</div>
			<form [formGroup]="userForm" *ngIf="!invalidEmail">
				<div class="row">
					<div class="col-12">
						<mat-form-field class="form-group">
							<mat-label>{{ 'registration.forgot-password.form.first-name' | translate }}</mat-label>
							<input matInput formControlName="firstName" />
							<mat-error>
								<app-error-message [control]="'firstName'"></app-error-message>
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-12">
						<mat-form-field class="form-group">
							<mat-label>{{ 'registration.forgot-password.form.last-name' | translate }}</mat-label>
							<input matInput formControlName="lastName" />
							<mat-error>
								<app-error-message [control]="'lastName'"></app-error-message>
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-12">
						<mat-form-field class="form-group">
							<mat-label>{{ 'registration.forgot-password.form.dob' | translate }}</mat-label>
							<input matInput formControlName="dateOfBirth" [imask]="dobMask.iMaskArgs" />
							<mat-hint *ngIf="showHint">{{ dateFormat }}</mat-hint>
							<mat-error>
								<app-error-message control="dateOfBirth"></app-error-message>
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-12 d-flex justify-content-between align-items-center mt-4">
						<button
							class="button secondary-btn basic button-medium"
							mat-stroked-button
							appEheAnalytics
							[data]="{
								pageUrl: '/forgot/password',
								redirectedTo: '/login',
								componentName: 'forgot-password-link',
								id: 'Back to Login - Link',
								placement: 'Forgot password - link'
							}"
							appGoogleAnalytics
							[eventData]="{
								eventCategory: 'Link',
								eventAction: 'Forgot Password- Back To SignIn',
								eventLabel: 'Back to Sign In'
							}"
							(click)="returnToLogin()">
							<span [translate]="'registration.forgot-password.links.back'">Back</span>
						</button>
						<button class="button button-medium" mat-button (click)="getUserDetails()">
							<span [translate]="'registration.forgot-password.form.submit-button'">Next</span>
						</button>
					</div>
				</div>
			</form>
		</div>
		<div id="textContent" class="align-center invalid-email" *ngIf="invalidEmail">
			<span tabindex="0" [translate]="'registration.forgot-password.email-not-recognized'">
				We do not recognize your email address.
			</span>
			<br />
			<span tabindex="0" [translate]="'registration.forgot-password.contact-member-services'">
				Please contact Member Service at 1-800-362-8671 or
			</span>
		</div>
	</div>
</div>
