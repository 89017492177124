import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ValidationUtilityService } from 'src/app/modules/shared/utils/validation.util/validation-utility.service';
import { AuthService } from 'src/app/services/core/auth.service';
import { ServiceRouter } from 'src/app/services/core/http-service-router.service';
import { Environment } from 'src/app/config/environment';
import { METHOD_TYPE } from 'src/app/constants/service.constants';
import { ErrorMessages } from 'src/app/config/error-messages';
import { Config } from 'src/app/config/config';
import { SpinnerComponent } from 'src/app/modules/shared/spinner/spinner.component';
import { MigrationStatusService } from '../../../services/migration/migration-status.service';
import { VerificationBean, ProfileRelatedInfoBean } from 'src/app/beans/account/account-module.bean';
import emailMask from 'email-mask';
import { ProfileService } from '../../../services/core/profile.service';
import { FeedsService } from '../../../services/dashboard/feeds.service';
import { AppointmentsService } from '../../../services/scheduling/appointment/appointments.service';
import { DataCarrierService } from '../../../services/core/data-carrier.service';
import { AppUtil } from '../../../utils/app.util';
import { passwordSpecialCharsValidatorRegExp } from 'src/app/constants/app.constants';
import { AUTH_COMPONENTS } from 'src/app/constants/scheduling.constants';

@Component({
	selector: 'app-migration-password',
	templateUrl: './migration-password.component.html',
	styleUrls: ['./migration-password.component.scss'],
})
export class MigrationPasswordComponent implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];

	user: any = '';

	newPasswordForm: FormGroup;

	/** error in the verification code entry */
	verificationCodeError: boolean = false;

	/** flag denote cognito error limitExceeded */
	ifLimitExceededError: boolean = false;

	/** holding cognito error messages */
	errorMsg: string;

	/** flag denote any error on reset password */
	ifAnyError: boolean = false;

	/* Boolean to determine if its an email address sign up */
	signup: boolean = false;

	/** Holds return url value */
	returnUrl: string = '';

	/** Flag to hide password strength block on focus */
	hidePasswordStrengthBlock: boolean = true;

	/** Custom Password Special Characters Validator RegExp */
	passwordSpecialCharsValidatorRegExp: RegExp = passwordSpecialCharsValidatorRegExp;

	constructor(
		private router: Router,
		private serviceRouter: ServiceRouter,
		private config: Config,
		private environment: Environment,
		private errorMessages: ErrorMessages,
		private authService: AuthService,
		private spinner: SpinnerComponent,
		private fb: FormBuilder,
		private activatedRoute: ActivatedRoute,
		private migrationService: MigrationStatusService,
		private profileService: ProfileService,
		private feedsService: FeedsService,
		private appointmentService: AppointmentsService,
		private dataCarrierService: DataCarrierService
	) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.activatedRoute.queryParams.subscribe(params => {
				this.user = JSON.parse(atob(params['user']));
				if (this.user.email) {
					this.user.maskedEmail = emailMask(this.user.email, '*');
				}
				if (params['signup'] === 'true') {
					this.signup = true;
				}
				this.returnUrl = params['returnUrl'];
			})
		);
		this.setupNewPasswordForm();
		if (!this.signup) {
			this.resendVerificationCodeFromCognito();
		}
	}

	/**
	 * Requests verification code
	 */
	requestVerificationCode() {
		if (this.signup && this.user.email) {
			this.resendVerificationCodeFromEpms();
		} else if (!this.signup && this.user.email) {
			this.resendVerificationCodeFromCognito();
		}
	}

	resendVerificationCodeFromCognito() {
		this.spinner.startLoader();
		this.authService.forgotPassword(this.user.email);
		this.subscriptions.push(
			this.authService.getResult().subscribe(
				res => {
					this.spinner.stopLoader();
					if (res.success) {
						this.verificationCodeError = false;
						this.ifLimitExceededError = false;
						this.ifAnyError = false;
						this.errorMsg = undefined;
					}
				},
				error => {
					this.ifAnyError = true;
					this.errorMsg = '';
					if (error.object.code === this.config.exceptions.cognito.USER_NOT_FOUND) {
						this.errorMsg = this.errorMessages.text.userNotExistError;
					} else if (error.object.code === this.config.exceptions.cognito.LIMIT_EXCEEDED) {
						this.ifLimitExceededError = true;
						this.errorMsg = this.errorMessages.text.limitExceededError;
					} else {
						this.errorMsg = error.object.message;
					}
					this.spinner.stopLoader();
					console.error(
						`[${MigrationPasswordComponent.name}][${this.resendVerificationCodeFromCognito.name}]`,
						'Error on Reset password Resend Verification Code ',
						error
					);
				}
			)
		);
	}

	/** resend Verification code */
	resendVerificationCodeFromEpms() {
		// resetting the form
		this.verificationCodeError = false;
		const request = new VerificationBean();
		request.uuid = this.user.uuid;
		request.emailAddress = this.user.email;
		request.verificationCode = this.newPasswordForm.getRawValue().code;
		this.spinner.startLoader();
		this.subscriptions.push(
			this.serviceRouter
				.makeRequest(
					this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
					this.config.url.registrationApi.createAccount + `/${this.user.epmsPID}/verification/resend`,
					METHOD_TYPE.GENERAL_POST,
					request
				)
				.subscribe(
					(response: any) => {
						this.spinner.stopLoader();
					},
					err => {
						this.spinner.stopLoader();
						console.error(
							`[${MigrationPasswordComponent.name}][${this.resendVerificationCodeFromEpms.name}]`,
							'Error occurred while resending verification code during registration flow to email : ',
							this.user.email,
							err
						);
					}
				)
		);
	}

	/*
	 *   Sets up the new password form
	 */
	private setupNewPasswordForm(): void {
		this.newPasswordForm = this.fb.group(
			{
				code: new FormControl(
					'',
					Validators.compose([Validators.required, ValidationUtilityService.verificationCodeValidator])
				),
				password: new FormControl(
					'',
					Validators.compose([Validators.required, ValidationUtilityService.passwordValidator])
				),
				confirmPassword: new FormControl(
					'',
					Validators.compose([Validators.required, ValidationUtilityService.passwordValidator])
				),
			},
			{
				validator: ValidationUtilityService.matchingPasswords.bind(this),
			}
		);
	}

	/**
	 * Reset password from both cognito & EPMS
	 */
	resetPassword() {
		this.ifAnyError = false;
		this.errorMsg = undefined;
		const newPasswordFormDetails = this.newPasswordForm.getRawValue();
		const newPassword = newPasswordFormDetails.password;
		const code = newPasswordFormDetails.code;
		if (this.newPasswordForm.valid) {
			if (this.user) {
				if (this.user.email && !this.signup) {
					this.spinner.startLoader();
					this.authService.resetPassword(this.user.email, code, newPassword);
					this.subscriptions.push(
						this.authService.getResult().subscribe(
							res => {
								if (res.success) {
									this.updateMigrationStatus();
								}
								this.spinner.stopLoader();
							},
							err => {
								console.error(
									`[${MigrationPasswordComponent.name}][${this.resetPassword.name}]`,
									'Error during Password reset',
									this.user,
									err
								);
								if (err.object.code === this.config.exceptions.cognito.CODE_MISMATCH) {
									this.errorMsg = "We didn't recognize the verification code you entered.  Please try again.";
									this.verificationCodeError = true;
									this.ifAnyError = true;
									this.newPasswordForm.controls.code.setErrors({
										invalid: true,
									});
								} else if (err.object.code === this.config.exceptions.cognito.LIMIT_EXCEEDED) {
									this.errorMsg = this.errorMessages.text.limitExceededError;
									this.ifAnyError = true;
									this.ifLimitExceededError = true;
								} else {
									this.ifAnyError = true;
									this.errorMsg = this.errorMessages.text.resetPasswordError;
								}
								this.spinner.stopLoader();
							}
						)
					);
				} else if (this.signup) {
					this.verifyCode();
				} else {
					this.newPasswordForm.get('password').markAsTouched();
					this.newPasswordForm.get('confirmPassword').markAsTouched();
				}
			}
		} else {
			this.ifAnyError = true;
			this.errorMsg = 'Please input all information to create your new password';
		}
	}

	verifyCode() {
		if (this.newPasswordForm.valid) {
			const request = new VerificationBean();
			request.uuid = this.user.uuid;
			request.emailAddress = this.user.email;
			request.verificationCode = this.newPasswordForm.getRawValue().code;
			this.spinner.startLoader();
			this.subscriptions.push(
				this.serviceRouter
					.makeRequest(
						this.environment.getEnvironment().EPMS_PROXY_BASE_URL,
						this.config.url.registrationApi.createAccount + `/${this.user.epmsPID}/verification`,
						METHOD_TYPE.GENERAL_POST,
						request
					)
					.subscribe(
						(response: any) => {
							this.spinner.stopLoader();
							this.authService.signUpUser({
								email: this.user.email,
								password: this.newPasswordForm.getRawValue().password,
							});
							this.subscriptions.push(
								this.authService.getSignUpResult().subscribe(
									res => {
										this.spinner.stopLoader();
										this.updateAccount();
									},
									error => {
										this.ifAnyError = true;
										this.errorMsg = 'You password does not meet security requirements. Please try again.';
										console.error(
											`[${MigrationPasswordComponent.name}][${this.verifyCode.name}][1]`,
											'Error while creating user account in cognito',
											this.user.email,
											error
										);
										this.spinner.stopLoader();
									}
								)
							);
						},
						err => {
							this.spinner.stopLoader();
							this.newPasswordForm.controls.code.setErrors({ invalid: true });
							this.errorMsg = "We didn't recognize the verification code you entered.  Please try again.";
							this.verificationCodeError = true;
							this.ifAnyError = true;
							console.error(
								`[${MigrationPasswordComponent.name}][${this.verifyCode.name}][2]`,
								'Error occurred while sending verification code during registration flow to email : ',
								this.user.email,
								err
							);
						}
					)
			);
		}
	}

	updateAccount() {
		const newPasswordFormDetails = this.newPasswordForm.getRawValue();
		const newPassword = newPasswordFormDetails.password;
		this.subscriptions.push(
			this.migrationService.updateAccount(this.user.epmsPID, this.user.uuid, this.user.email, newPassword).subscribe(
				res => {
					this.updateMigrationStatus();
				},
				err => {
					console.error(
						`[${MigrationPasswordComponent.name}][${this.updateAccount.name}]`,
						'An error occurred while updating account status'
					);
				}
			)
		);
	}

	/**
	 * Updates migration status of status
	 */
	updateMigrationStatus() {
		const body = {
			epmsPID: this.user.epmsPID,
			email: this.user.email,
		};
		this.migrationService.updateMigrationStatus(body).subscribe(
			res => {
				this.loginUser();
				sessionStorage.setItem('createAccountToaster', '1');
			},
			err => {
				console.error(
					`[${MigrationPasswordComponent.name}][${this.updateMigrationStatus.name}]`,
					'An error occurred while updating account status'
				);
			}
		);
	}

	/**
	 * Login the user immediately after creating account
	 */
	loginUser() {
		console.info('migration password component');
		this.spinner.startLoader();
		this.authService.login(this.user.email, this.newPasswordForm.getRawValue().password, this.signup ? AUTH_COMPONENTS.SIGNUP_COMPONENT : AUTH_COMPONENTS.LOGIN_COMPONENT);
		this.subscriptions.push(
			this.authService.getResult().subscribe(response => {
				if (this.router.routerState.snapshot.url.includes('embed')) {
					this.spinner.stopLoader();
					window.open(`${window.location.origin}/dashboard`, '_top');
				} else {
					if (this.returnUrl !== '' && this.returnUrl !== undefined) {
						this.profileService.getProfileInfo().subscribe(
							(res: ProfileRelatedInfoBean) => {
								this.spinner.stopLoader();
								const profileInfo = res;
								if (profileInfo.profileInfo.userMatched) {
									if (this.returnUrl.includes('scheduling/create/appointment')) {
										sessionStorage.setItem('RETURN_URL', 'true');
									}
									this.router.navigateByUrl(`${this.returnUrl}`);
								} else {
									this.router.navigate(['dashboard']);
								}
							},
							err => {
								this.spinner.stopLoader();
							}
						);
					} else {
						AppUtil.redirectToScheduling(
							this.router,
							this.feedsService,
							this.appointmentService,
							this.dataCarrierService
						);
					}
				}
			})
		);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
		this.subscriptions = [];
	}
}
