import { Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import {
	Router,
	Event,
	NavigationStart,
	NavigationEnd,
	NavigationError,
	ActivatedRoute,
	NavigationCancel,
} from '@angular/router';
import { AnalyticsService } from './services/analytics/analytics.service';
import { AuthService } from './services/core/auth.service';
import { Environment } from './config/environment';
import { HttpClient } from '@angular/common/http';
import { ProfileRelatedInfoBean } from './beans/account/account-module.bean';
import { DataCarrierService } from './services/core/data-carrier.service';
import {
	AnalyticsAuthPageViewBean,
	AuthEventValueBean,
	AnalyticsUserBean,
} from './beans/analytics/analytics-module.bean';
import { ModalService } from './services/core/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { JwtHelper } from './services/core/jwt-helper.service';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { decode } from 'he';
import { stringify } from 'flatted';
import { EheVerificationModalComponent } from './modules/shared/ehe-modal/verification-modal/ehe-verification-modal.component';
import { SpinnerComponent } from './modules/shared/spinner/spinner.component';
import { BrowserNotificationModalComponent } from './modules/shared/ehe-modal/browser-notification-modal/ehe-browser-notification-modal.component';
import { MfaService } from './services/core/mfa.service';

/** App Component */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
	/** holds analytics service object */
	analyticsService: AnalyticsService;
	/** holds environment object */
	env: any;
	/** utm object */
	utm: any;
	/** Holds profile related info */
	profileRelatedInfo: ProfileRelatedInfoBean;
	/** flag to check if user is matched */
	userMatched: boolean;
	/** flag to check if user is eligible for pe */
	eligibleForPE: any;
	/** holds user company details */
	company: any;
	/** analytics auth bean */
	analyticsAuthPageViewBean: AnalyticsAuthPageViewBean;
	/** holds data carrier subscriptions */
	dataCarrierSubscription: any;

	private unsubscribe$ = new Subject<void>();

	private subscriptions: Subscription[] = [];

	isInactivityModalOpen = false;
	warningTimeoutId;
	logoutTimeoutId;
	msUntilWarningDisplayed = 14 * 60 * 1000; // use one minute in test, use 14 minutes in prod (w/ 1 min warning)
	msUntilWarningLogsOut = 1 * 60 * 1000;
	displayMfaInputScreen = this.mfaService.displayMfaInputScreen;

	@HostListener('window:keydown')
	@HostListener('window:mousedown')
	@HostListener('window:mousemove')
	startActivityTimer(): void {
		if (this.authService.isLoggedIn()) {
			this.clearWarningTimer();
			this.warningTimeoutId = setTimeout(() => {
				this.startLogoutTimer();
				if (!this.isInactivityModalOpen) {
					this.ms.dialog
						.open(EheVerificationModalComponent, {
							width: '500px',
							disableClose: true,
							data: {
								button1: 'Continue Session',
								button2: 'Logout',
								modalWidth: '500px',
								content: `Are you still there?  To protect the safety of your account, we'll log you out unless you indicate you're still actively using myEHE.`,
								alignCenterButtonCenter: false,
								fromCreateAccount: true, // poorly named config which we use to close dialog after button click
							},
						})
						.afterClosed()
						.pipe(takeUntil(this.unsubscribe$))
						.subscribe(x => {
							this.isInactivityModalOpen = false;
							console.log('closed with value', x);
							if (x.value == 'cancel') {
								this.initiateLogout();
							}
							this.clearTimers();
						});
					this.isInactivityModalOpen = true;
				}
			}, this.msUntilWarningDisplayed);
		}
	}

	initiateLogout(shouldDisplayMessage = false): void {
		this.clearTimers();
		this.dialog.closeAll();
		this.authService.logoutUser();
		const queryParams = { queryParams: { tab: 'login' } };
		if (shouldDisplayMessage) {
			queryParams.queryParams['m'] = 'inactive';
		}
		console.log('queryParams', queryParams);
		this.router.navigate(['/login'], queryParams);
	}

	clearWarningTimer(): void {
		if (this.warningTimeoutId) {
			clearTimeout(this.warningTimeoutId);
		}
	}

	clearLogoutTimer(): void {
		if (this.logoutTimeoutId) {
			clearTimeout(this.logoutTimeoutId);
		}
	}

	clearTimers(): void {
		this.clearWarningTimer();
		this.clearLogoutTimer();
	}

	startLogoutTimer(): void {
		this.clearLogoutTimer();
		this.logoutTimeoutId = setTimeout(() => {
			this.initiateLogout(true);
		}, this.msUntilWarningLogsOut);
	}

	/**
	 * App Component constructor
	 * @param ms - Modal service
	 * @param translate - Translate Service
	 * @param router - Angular Router
	 * @param authService - Authentication Service
	 * @param environmentConfig -Environment Config
	 * @param http - Angular HTTP Client
	 * @param activatedParams - Angular Activated route
	 * @param dialog - dialog service
	 * @param dataCarrierService - Data Carrier Service
	 */
	constructor(
		private router: Router,
		private authService: AuthService,
		private environmentConfig: Environment,
		http: HttpClient,
		private activatedParams: ActivatedRoute,
		private ms: ModalService,
		private translate: TranslateService,
		private dialog: MatDialog,
		private dataCarrierService: DataCarrierService,
		cookieService: CookieService,
		private jwtService: JwtHelper,
		private spinner: SpinnerComponent,
		private mfaService: MfaService
	) {
		this.startActivityTimer();

		this.subscriptions.push(
			this.mfaService.fetchGlobalMfaStatus()
		);

		const ssoType = localStorage.getItem('sso-type');
		if (!ssoType && !this.authService.isLoggedIn()) {
			this.authService.getUnauthCredentials();
		}
		this.analyticsAuthPageViewBean = new AnalyticsAuthPageViewBean();
		this.analyticsService = new AnalyticsService(
			this.authService,
			environmentConfig,
			cookieService,
			http,
			this.jwtService
		);
		this.env = this.environmentConfig.getEnvironment();
		this.utm = {
			utmSource: '',
			utmMedium: '',
			utmTerm: '',
			utmContent: '',
			utmCampaign: '',
		};

		window.addEventListener('click', e => {
			if (window.innerWidth <= 991) {
				this.customDropDownHandler(e, 'mobile-sub-menu', 'responsiveMenu');
				this.customDropDownHandler(e, 'global-sidebar-on-small-screen-li', '_submenu');
			}

			const listOfElements: NodeListOf<Element> =
				document.getElementsByClassName('custom-material-position')[Symbol.iterator];
			if (Array.isArray(listOfElements)) {
				for (const element of listOfElements as any) {
					const boundingRect = element.getBoundingClientRect();
					if (window.innerHeight < boundingRect.y + boundingRect.height) {
						element.style.bottom = '28px';
						element.style.top = 'auto';
					} else {
						element.style.top = '28px';
						element.style.bottom = 'auto';
					}
				}
			}
		});

		if (window.console && console.error) {
			const _self = this;
			const consoleErrorFnObject = console.error;
			console.error = function (...error: any[]) {
				const processedError = error.map(e => {
					if (e.stack) {
						return e.stack;
					} else if (typeof e === 'object') {
						return stringify(e);
					} else {
						return e;
					}
				});
				processedError.push(`Page URL - ${window.location.href}`);
				// _self.analyticsService.setBucketName(_self.env.S3_UI_LOGS_BUCKET);
				// pushLogs it self will maintain the error console switch
				_self.analyticsService.pushLogs(processedError, consoleErrorFnObject);
				try {
					consoleErrorFnObject.call(console, processedError);
				} catch (error) { }
			};
		}

		if (window.console && !console['logToS3']) {
			const _self = this;
			console['logToS3'] = function (...logs: any[]) {
				const processedLogs = logs.map(log => {
					if (typeof log === 'object') {
						return JSON.stringify(log);
					} else {
						return log;
					}
				});
				processedLogs.push(`Page URL - ${window.location.href}`);
				_self.analyticsService.pushLogs(processedLogs, console.log, false);
				console.log(processedLogs);
			};
		}

		const urlParams = new URLSearchParams(decode(window.location.search));
		const params = {
			utm_source: urlParams.get('utm_source'),
			utm_medium: urlParams.get('utm_medium'),
			utm_term: urlParams.get('utm_term'),
			utm_content: urlParams.get('utm_content'),
			utm_campaign: urlParams.get('utm_campaign'),
		};
		if (params && Object.keys(params).length !== 0) {
			this.authService.setUtmParameters(params);
		}

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationStart) {
				/* Start the loader while navigating to the respective pages */
				if (event.url === '/pulse-virtual/home') {
					this.spinner.startLoader();
				}
				if (
					event.url === '/' ||
					event.url === '/registration' ||
					event.url === '/login' ||
					event.url === '/forgot/password' ||
					event.url === '/reset/password' ||
					event.url === '/request/email' ||
					event.url === '/dashboard'
				) {
				} else {
					if (localStorage.getItem('isMatchedUser') != null) {
						if (localStorage.getItem('isMatchedUser') === 'false') {
							this.router.navigate(['dashboard']);
						}
					}
				}

				if (event.url == '/' || event.url == '/dashboard' || event.url == '/login') {
					// browser check
					this.ieBrowserCheck();
				}
			}

			if (event instanceof NavigationEnd) {
				this.analyticsPushData(event);
				this.googleAnalyticsPageView(event, '');
				// Params added as part of FITEHEME-1349
				try {
					const params = event.url.split('?') || [];
					const munchkinObject = { url: event.url, params: params[1] || '' };
					if ((<any>window).Munchkin) {
						(<any>window).Munchkin.munchkinFunction('visitWebPage', munchkinObject);
					} else {
						console.log(`Munchkin not initialized: ${munchkinObject.url}`);
					}
				} catch (err) {
					console.error(`[${AppComponent.name}]`, 'Error sending visit web page event', err);
				}

				/* Stop the loader after navigating to the respective pages */
				if (event.url == '/pulse-virtual/home') {
					this.spinner.stopLoader();
				}
			}

			if (event instanceof NavigationError) {
				// Hide loading indicator
				this.analyticsPushData(event);
				// Present error to user

				/* Stop the loader in case of error navigating to respective pages */
				if (event.url == '/pulse-virtual/home') {
					this.spinner.stopLoader();
				}
			}

			if (event instanceof NavigationCancel) {
				/* Stop the loader in case of navigation to respective page is cancelled */
				if (event.url == '/pulse-virtual/home') {
					this.spinner.stopLoader();
				}
			}
		});
	}

	ngOnInit(): void {
		console.info('ENV_KEY:', this.environmentConfig.getEnvironment().ENV_KEY);
	}

	/* check and DisplayPopup if the browser is IE - EH-14 */
	async ieBrowserCheck() {
		const { detect } = await import('detect-browser');
		const browser = detect();

		/* handle the case where we don't detect the browser */
		switch (browser && browser.name) {
			case 'ie':
				this.displayIENotificationPopup();
				break;
		}
	}

	displayIENotificationPopup() {
		const dialogRef = this.dialog.open(BrowserNotificationModalComponent, {
			maxWidth: '600px',
			disableClose: true,
		});
	}
	/*end of modal box addition */

	/**
	 * Google analytics for page view
	 * @param event - Router Event
	 * @param eventTrigger
	 */
	googleAnalyticsPageView(event: any, eventTrigger: any) {
		if (localStorage.getItem('utm')) {
			this.utm = JSON.parse(localStorage.getItem('utm'));
			if (this.utm) {
				(<any>window).dataLayer.push({
					'event': 'UpdateUTM',
					'utm-medium': this.utm.utmMedium,
					'utm-source': this.utm.utmSource,
					'utm-content': this.utm.utmContent,
					'utm-campaign': this.utm.utmCampaign,
					'utm-term': this.utm.utmTerm,
				});
			}
		}
		(<any>window).dataLayer.push({
			event: 'pageview',
		});
	}

	/**
	 * Pushing pageview data to analytics services
	 * @param event Router Event
	 */
	analyticsPushData(event) {
		try {
			event.url = event.url.split('?')[0];
			this.utm = JSON.parse(localStorage.getItem('utm'));
			if (event.url !== '/dashboard') {
				const profile =
					this.dataCarrierService.getChannel() && this.dataCarrierService.getChannel().getValue()
						? this.dataCarrierService.getChannel().getValue().profile
						: undefined;
				const ssoType = localStorage.getItem('sso-type');
				let email, epmsPID;
				if (ssoType) {
					epmsPID = this.jwtService.getEpmsId();
				} else {
					const auth = sessionStorage.getItem('auth');
					if (auth) {
						email = JSON.parse(sessionStorage.getItem('auth')).idToken.payload.email;
						epmsPID = JSON.parse(sessionStorage.getItem('auth')).idToken.payload['custom:epms-p-id'];
					}
				}
				const user = new AnalyticsUserBean();
				user.email = email;
				user.uuid = epmsPID;
				if (profile && profile.profileInfo) {
					const profileInfo = profile.profileInfo;
					user.company = profileInfo.client.name;
					user.eligibleForPE = profileInfo.eligibleForPE;
					user.userMatched = profileInfo.userMatched;
					user.personaType = profile.personaType;
				}
				if (ssoType) {
					user.ssoType = ssoType;
				}
				this.analyticsAuthPageViewBean.eventLabel = 'pageView';
				this.analyticsAuthPageViewBean.eventComponent = event.url;
				const authEventValueBean = new AuthEventValueBean();
				authEventValueBean.user = user;
				authEventValueBean.utm = this.utm;
				authEventValueBean.status = JSON.parse(localStorage.getItem('status'));
				this.analyticsAuthPageViewBean.eventValue = authEventValueBean;
				this.analyticsService.pushData(this.analyticsAuthPageViewBean);

				if (localStorage.getItem('isMatchedUser') != null) {
					if (localStorage.getItem('isMatchedUser') === 'false') {
					}
				}
			}
		} catch (e) {
			console.error(`[${AppComponent.name}][${this.analyticsPushData.name}]`, 'error while pushing analytics pageview data', e);
		}
	}

	/**
	 * click event handler for mobile submenu
	 * @param e - click event
	 * @param container - handling based on Class name
	 * @param elementId - handling based on element id
	 */
	customDropDownHandler(e: any, container: any, elementId: string) {
		if (e.target['nodeName'] === 'LI' && e.target['classList'].contains(container)) {
			let style = 'block';
			if (e.target['parentNode'].classList.contains('opened')) {
				style = 'none';
				e.target['parentNode'].classList.remove('opened');
			} else {
				e.target['parentNode'].classList.add('opened');
			}
			const node = e.target['parentNode'].children;
			for (const element of node) {
				if (!element.classList.contains('active')) {
					element.style.display = style;
				}
			}
		} else {
			const htmlElement = document.getElementById(elementId);
			if (htmlElement !== null) {
				const children: any = htmlElement.children;
				if (htmlElement.classList.contains('opened')) {
					for (const element of children) {
						if (!element.classList.contains('active')) {
							element.style.display = 'none';
						}
					}
					htmlElement.classList.remove('opened');
					e.stopPropagation();
				}
			}
		}
	}

	/**
	 * Destroys the component
	 */
	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.unsubscribe();
		if (this.dataCarrierSubscription) {
			this.dataCarrierSubscription.unsubscribe();
		}
		this.clearTimers();
	}
}
