/** Appointment status constants */
export enum APPOINTMENT_STATUS {
	CONFIRMED = 'Confirmed',
	PENDING = 'Request Pending',
	PRE_BOOKING = 'Pre-Booking',
	CONFIRM_PENDING = 'Confirm Pending',
	PENDING_APPOINTMENT_CONFIRMATION = 'Pending Appointment Confirmation',
}

/** Procedure Message Modal Answers constants */
export enum PROCEDURE_MESSAGE_MODAL_ANSWERS {
	YES = 'YES',
	NO = 'NO',
}

/** Procedure constants */
export enum PROCEDURE_CONSTANTS {
	PHYSICAL_EXAM = 'Physical Exam',
	PHYSICAL_EXAM_ID = 1,
	VIRTUAL_EXAM = 'Pulse Wellness',
	VIRTUAL_EXAM_ID = -1,
	INOCULATION = 'Inoculation',
	CUTOFF_DATE_FOR_NEW_HA = '08/20/2021', // cutoff is 8/19/2021 so any date on or before is validated
	RESCHEDULE = 'reschedule',
	HEALTH_COACHING = 'Health Coaching',
	HEALTH_COACHING_ID = -2,
}

export enum AUTH_COMPONENTS {
	LOGIN_COMPONENT = 'login-component',
	SIGNUP_COMPONENT = 'signup',
	RESET_COMPONENT = 'reset'
}

/** Physical exam status constants */
export enum PE_STATUS {
	NOT_SCHEDULED = 1,
	SCHEDULED = 2,
	COMPLETED = 3,
}

export enum DAYS_LEFT {
	PHYSICAL_EXAM = 90,
}

export enum INCENTIVE_CONSTANTS {
	MANUAL_CODE_KEY = 'code',
	PAYLOAD_KEY = 'incentiveCode',
	DYNAMIC_DELAY_MS = 800,
	PROMO_QUERY_KEY = 'pc',
	REFERRAL_QUERY_KEY = 'rc',
	NONE_TYPE = 'NONE',
	PROMO_TYPE = 'PROMO_CODE',
	REFERRAL_TYPE = 'REFERRAL_CODE',
	RETURN_URL_ADDITIONAL_DATA_KEY = 'promoReferralData',
	PROMO_DEFAULT_MESSAGE = 'Promo Code is valid',
	REFERRAL_DEFAULT_MESSAGE = 'Referral Code is valid',
	PROMO_DEFAULT_ERROR = 'Promo Code is not valid',
	REFERRAL_DEFAULT_ERROR = 'Referral Code is not valid',
	REFERRAL_SELF_CODE_ERROR = 'SELF_CODE',
}

/** Appointment status constants */
export enum PW_APPOINTMENT_STATUS {
	CANCELLED = 'Coach Cancelled',
	NO_SHOW = 'No Show',
	BOOKED = 'Booked',
}

export enum PV_LAB_STATUS {
	COMPLETED = 'Completed',
	IN_PROGRESS = 'In Progress',
	NOT_REGISTERED = 'Not registered',
}

export const PV_RETIRE_DATE = '2123-11-26';
export const PV_SCHEDULING_RETIRE_DATE = '2124-01-01';

export enum MAP_MARKER_TYPES {
	/**
	 * Default marker from MapLibre GL Library \
	 * Can show numbers
	 */
	MAPLIBRE_GL_DEFAULT = 'MAPLIBRE_GL_DEFAULT',
	/**
	 * Default marker from MapLibre GL Amplify Library \
	 * CAN NOT show numbers
	 */
	MAPLIBRE_GL_AMPLIFY_DEFAULT = 'MAPLIBRE_GL_AMPLIFY_DEFAULT',
	/**
	 * Custom HTML
	 * Available variants are defined in {@link CUSTOM_MAP_MARKERS} \
	 * Can show numbers
	 * @default CUSTOM_MAP_MARKERS.CUSTOM_HTML_1
	 */
	CUSTOM_HTML = 'CUSTOM_HTML',
}

export enum CUSTOM_MAP_MARKERS {
	/**
	 * Custom map marker - Variant 1 \
	 * Can show numbers
	 * This is the default Marker if `MAP_MARKER_TYPES.CUSTOM` is used
	 */
	CUSTOM_HTML_1 = 'CUSTOM_HTML_1',
	/**
	 * Custom map marker - Variant 2 \
	 * Can show numbers
	 */
	CUSTOM_HTML_2 = 'CUSTOM_HTML_2',
	/**
	 * Custom map marker - Variant3 \
	 * Can show numbers
	 */
	CUSTOM_HTML_3 = 'CUSTOM_HTML_3',
	/**
	 * Default marker from MapLibre GL Amplify Library modified to show numbers \
	 * Can show numbers
	 */
	MAPLIBRE_GL_AMPLIFY = 'MAPLIBRE_GL_AMPLIFY',
}

/**
 * Custom map marker classes defined in their respective components
 */
export const CUSTOM_MAP_MARKER_CLASSES = Object.freeze({
	[CUSTOM_MAP_MARKERS.CUSTOM_HTML_1]: 'map-marker-custom-1',
	[CUSTOM_MAP_MARKERS.CUSTOM_HTML_2]: 'map-marker-custom-2',
	[CUSTOM_MAP_MARKERS.CUSTOM_HTML_3]: 'map-marker-custom-3',
	[CUSTOM_MAP_MARKERS.MAPLIBRE_GL_AMPLIFY]: 'map-marker-maplibre-gl',
});
