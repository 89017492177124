import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServiceRouter } from './http-service-router.service';
import { Environment } from 'src/app/config/environment';
import { Config } from 'src/app/config/config';
import { METHOD_TYPE } from 'src/app/constants/service.constants';
import { MfaSessionStorageKeys } from 'src/app/modules/shared/types';

@Injectable({
	providedIn: 'root',
})
export class MfaService {
	constructor(
		private injector: Injector
	) { }
	private displayMfaInputScreenSubject = new BehaviorSubject<boolean>(false);
	displayMfaInputScreen: Observable<boolean> = this.displayMfaInputScreenSubject.asObservable();

	private mfaCodeSubject = new BehaviorSubject<string>('');
	mfaCode: Observable<string> = this.mfaCodeSubject.asObservable();

	private showMfaErrorMessageSubject = new BehaviorSubject<boolean>(false);
	showMfaErrorMessage: Observable<boolean> = this.showMfaErrorMessageSubject.asObservable();

	private showMfaErrorMessageValueSubject = new BehaviorSubject<string>('');
	mfaErrorMessage: Observable<string> = this.showMfaErrorMessageValueSubject.asObservable();

	private usernameSubject = new BehaviorSubject<string>('');
	username = this.usernameSubject.asObservable();

	private passwordSubject = new BehaviorSubject<string>('');
	password = this.passwordSubject.asObservable();

	private userFirstNameSubject = new BehaviorSubject<string>('');
	userFirstName = this.userFirstNameSubject.asObservable();

	private mfaACtivatedAtSubject = new BehaviorSubject<string>('');
	mfaActivatedAt = this.mfaACtivatedAtSubject.asObservable();

	private displayMfaSuccessScreenSubject = new BehaviorSubject<string>('');
	displayMfaSuccessScreen: Observable<string> = this.displayMfaSuccessScreenSubject.asObservable();

	private codeDeliveryDestinationSubject = new BehaviorSubject<string>('');
	codeDeliveryDestination = this.codeDeliveryDestinationSubject.asObservable();

	toggleDisplayMfaSuccessScreen(showMfa: string) {
		this.displayMfaSuccessScreenSubject.next(showMfa);
	}

	toggleDisplayMfaInputScreen(showMfa: boolean) {
		this.displayMfaInputScreenSubject.next(showMfa);
	}

	displayMfaErrorMessage(showError: boolean, errorMessage: string) {
		this.showMfaErrorMessageSubject.next(showError);
		this.showMfaErrorMessageValueSubject.next(errorMessage);
	}

	setMfaCode(code: string) {
		this.mfaCodeSubject.next(code);
	}
	setUsername(username: string) {
		this.usernameSubject.next(username);
	}

	setPassword(password: string) {
		this.passwordSubject.next(password);
	}

	setUserFirstName(username: string) {
		this.userFirstNameSubject.next(username);
	}

	setMfaActivatedAt(status: string) {
		this.mfaACtivatedAtSubject.next(status);
	}

	setCodeDeliveryDestination(codeDeliveryDestination: string) {
		this.codeDeliveryDestinationSubject.next(codeDeliveryDestination);
	}

	fetchGlobalMfaStatus() {
		console.info('Fetching global-mfa-status:');

		const config = this.injector.get(Config);
		const environment = this.injector.get(Environment);
		const serviceRouter = this.injector.get(ServiceRouter);

		const subscription = serviceRouter
			.makeRequest(
				environment.getEnvironment().EPMS_PROXY_BASE_URL,
				config.url.global.mfaStatus,
				METHOD_TYPE.GENERAL_GET,
				''
			)
			.subscribe({
				next: response => {
					console.info('global-mfa-status:', response);
					if (response) {
						sessionStorage.setItem(MfaSessionStorageKeys.globalMfaStatus, response.enabled);
					}
				},
				error: (error) => {
					console.error('Unable to fetch global-mfa-status:', error);
					sessionStorage.setItem(MfaSessionStorageKeys.globalMfaStatus, 'false');
				},
				complete: () => {
					subscription.unsubscribe();
				}
			});
		return subscription;
	}

	ss_getGlobalMfaStatus() {
		if (localStorage.getItem('sso') === 'true') {
			return false;
		} else {
			return sessionStorage.getItem(MfaSessionStorageKeys.globalMfaStatus) === 'true';
		}
	}

	ss_setGlobalMfaStatus(value: boolean) {
		if (localStorage.getItem('sso') === 'true') {
			sessionStorage.setItem(MfaSessionStorageKeys.globalMfaStatus, 'false');
		} else {
			sessionStorage.setItem(MfaSessionStorageKeys.globalMfaStatus, value.toString());
		}
	}

	ss_getUserMfaStatus() {
		return sessionStorage.getItem(MfaSessionStorageKeys.isMfaSetAtUserLevel) === 'true';
	}

	ss_setUserMfaStatus(value: boolean) {
		sessionStorage.setItem(MfaSessionStorageKeys.isMfaSetAtUserLevel, value.toString());
	}

	ss_getMfaActivatedAt() {
		return sessionStorage.getItem(MfaSessionStorageKeys.mfaActivatedAt);
	}

	ss_setMfaActivatedAt(value: string) {
		sessionStorage.setItem(MfaSessionStorageKeys.mfaActivatedAt, value);
	}

	ss_getMfaDisabledReason() {
		return sessionStorage.getItem(MfaSessionStorageKeys.mfaDisabledReason);
	}

	ss_setMfaDisabledReason(value: any) {
		sessionStorage.setItem(MfaSessionStorageKeys.mfaDisabledReason, value)
	}
}
